<template>
    <post-login-nav />
    <side-bar />
    <div class="container">
        <blockquote>Admins</blockquote>

        <div v-if="fetchAdminLoader">
            <div v-if="fetchAdminUsers.length">
                <table class="table striped highlight">
                    <thead>
                        <tr class="red-text text-darken-4">
                            <td>Name</td>
                            <td>Status</td>
                            <td>Manage</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="admin in fetchAdminUsers" :key="admin.adminID">
                            <td>{{admin.name}}</td>
                            <td>
                                <div v-if="admin.active == 1">
                                    <i class="material-icons green-text text-darken-4">done_all</i>
                                </div>
                                <div v-else>
                                    <i class="material-icons red-text text-darken-4">done</i>
                                </div>
                            </td>
                            <td>
                                <router-link :to="{name: 'inad', params: {iad: admin.ziel}}">
                                    <i class="material-icons red-text text-darken-4">arrow_forward</i>
                                </router-link>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-else>
                <p class="center red-text text-darken-4">{{ fetchAdminNegativeMessage }}</p>
            </div>
        </div>

        <div v-else class="center">
            <circular-preloader />
        </div>

        <!-- <div class="fixed-action-btn">
            <a class="btn-floating btn-large red darken-4 modal-trigger" href="#prosthíki">
                <i class="large material-icons">add</i>
            </a>
        </div> -->

        <div id="prosthíki" class="modal">
            <div class="modal-content">
                <h5 class="center red-text text-darken-4">On Board</h5>
                <form>
                    <div class="row">
                        <div class="col s12 m12 l12">
                            <div class="input-field">
                                <input id="adm_fname" type="text" class="validate" required autocomplete="off" v-model="admRegFName">
                                <label for="adm_fname" class="red-text text-darken-4">First Name</label>
                            </div>

                            <div class="input-field">
                                <input id="adm_lname" type="text" class="validate" required autocomplete="off" v-model="admRegLName">
                                <label for="adm_lname" class="red-text text-darken-4">Surname</label>
                            </div>
                        </div>
                        <div class="col s12 m12 l12">
                            <div class="input-field">
                                <input id="adm_uname" type="text" class="validate" required autocomplete="off" v-model="admRegUName">
                                <label for="adm_uname" class="red-text text-darken-4">Username</label>
                            </div>

                            <div class="input-field">
                                <input id="adm_tel" type="number" class="validate" required autocomplete="off" v-model="admRegTel">
                                <label for="adm_tel" class="red-text text-darken-4">Phone Number</label>
                            </div>
                        </div>

                        <section>
                            <div v-if="admRegLoader" class="center">
                                <circular-preloader />
                            </div>
                            <div v-else>
                                <button @click.prevent="addAdmin" class="btn red darken-4 white-text" style="border-radius: 7px; width: 100%">Add Admin</button>
                            </div>
                        </section>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <a class="modal-close waves-effect waves-green btn-flat red-text text-darken-4">Close</a>
            </div>
        </div>

    </div>
    <global-footer />
</template>

<script>
import axios from "axios";
import M from "materialize-css";
import PostLoginNav from '@/components/PostLoginNav.vue'
import SideBar from '@/components/SideBar.vue'
import GlobalFooter from '@/components/GlobalFooter.vue'
import CircularPreloader from '@/components/CircularPreloader.vue';

export default {
    data(){
        return{
            fetchAdminLoader: false,
            fetchAdminNegativeMessage: null,
            fetchAdminUsers: [],
            admRegFName: null,
            admRegLName: null,
            admRegUName: null,
            admRegTel: null,
            admRegLoader: false,
            authToken: window.atob(window.atob(localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs"))),
        }
    },
    mounted(){
        //this.getAdmins();
        this.returnToDash();
    },
    methods: {
        async getAdmins(){
            let data = new FormData();
            data.append("onboard_admin_alpha_id", this.authToken);

            try {
                const response = await axios.post("https://a.ivoting.co.ke/AhzuJAnDhfIkS/onAdmin", data)
                let returnCondition = response.data.error;
                let returnMessage = response.data.message;
                if (returnCondition === false) {
                    this.fetchAdminLoader = true;
                    this.fetchAdminUsers = response.data.user_Data;
                }else{
                    this.fetchAdminLoader = true;
                    this.fetchAdminUsers = [];
                    this.fetchAdminNegativeMessage = returnMessage;
                }
            } catch (error) {
                this.fetchAdminLoader = true;
                this.fetchAdminUsers = [];
                this.fetchAdminNegativeMessage = 'Network Error. Try Again';
            }
        },
        addAdmin(){
            if (this.admRegFName === "" || this.admRegFName === null || this.admRegFName.value === 0) {
                alert("Empty Field - First Name")
            }else if (this.admRegLName === "" || this.admRegLName === null || this.admRegLName.value === 0) {
                alert("Empty Field - Surname")
            }else if (this.admRegUName === "" || this.admRegUName === null || this.admRegUName.value === 0) {
                alert("Empty Field - Username")
            }else if (this.admRegTel === "" || this.admRegTel === null || this.admRegTel.value === 0) {
                alert("Empty Field - Phone Number")
            }else {
                this.admRegLoader = true;
                let data = new FormData();
                data.append("new_adm_first_name", this.admRegFName);
                data.append("new_adm_last_name", this.admRegLName);
                data.append("new_adm_user_name", this.admRegUName);
                data.append("new_adm_phone_no", this.admRegTel);
                axios.post("https://a.ivoting.co.ke/AhzuJAnDhfIkS/onAdminReg", data).then((res)=>{
                    let returnCondition = res.data['error'];
                    let returnMessage = res.data['message'];
                    if (returnCondition === false) {
                        this.admRegLoader = false;
                        M.toast({html: ''+returnMessage});
                        this.getAdmins();
                        this.closeModal();
                    }else{
                        this.admRegLoader = false;
                        M.toast({html: ''+returnMessage});
                    }
                }).catch((err) => {
                    this.admRegLoader = false;
                    this.fetchAdminNegativeMessage = 'Error. Contact The System Administrator'
                    // M.toast({html: ''+err});
                });
            }
        },
        closeModal(){
            var elem = document.getElementById("prosthíki");
            var instance = M.Modal.getInstance(elem);
            instance.close();
        },
        returnToDash(){
            this.$router.push({name: "router-dash"});
        },
    },
    components: { PostLoginNav, SideBar, GlobalFooter, CircularPreloader },
    created(){
        document.title = this.$route.meta.title;
    },
}
</script>

<style>

</style>