<template>
    <div class="container row">
        <div v-for="page in pages" :key="page.pageID" class="col s12 m12 l6">
            <div class="card-panel hoverable color-me-impressed" style="border-radius: 7px;">
                <div v-if="page.pageParam === true">
                    <router-link :to="{name: page.pageLink, params: page.paramValue}" style="text-decoration: none;">
                        <div class="card-content black-text">
                            <h5 class="card-title red-text text-darken-4">{{ page.pageName }}
                                <div class="chip">
                                    <i class="tiny material-icons">{{ page.pageIcon }}</i>
                                </div>
                            </h5>
                            <p class="justify">{{ page.pageDescription }}</p>
                        </div>
                    </router-link>
                </div>
                <div v-else>
                    <router-link :to="{name: page.pageLink, params: page.paramValue}" style="text-decoration: none;">
                        <div class="card-content black-text">
                            <h5 class="card-title red-text text-darken-4">{{ page.pageName }}
                                <div class="chip">
                                    <i class="tiny material-icons">{{ page.pageIcon }}</i>
                                </div>
                            </h5>
                            <p class="justify">{{ page.pageDescription }}</p>
                        </div>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            pages: [
                {pageID: 2, pageName: 'Assign', pageLink: 'assignPhone', pageParam: true, paramValue: {pageValue: 1}, pageIcon: 'phone', pageDescription: 'Assign Telephone Number to Voter'},
                {pageID: 3, pageName: 'iSearch', pageLink: 'stts', pageParam: false, pageIcon: 'person_search', pageDescription: 'Check The Status of a Voter'},
                {pageID: 4, pageName: 'Verification', pageLink: 'vstatus', pageParam: false, pageIcon: 'sync', pageDescription: 'Check The Verification Status of a Voter'},
            ],
        }
    }
}
</script>

<style scoped>
    .color-me-impressed{
        border-left-style: solid;
        border-left-width: 5px;
        border-left-color: #b71c1c;
    }
</style>