import router from '../router/index.js'

function obliviate() {
    localStorage.removeItem("xWtyHBansFSGcOAMsdOAfQmcnKAs");
    sessionStorage.removeItem("xWtyHBansFSGcOAMsdOAfQmcnKAs");

    localStorage.removeItem("xWtyHBinsFSGcOAMsdOAfQmcnKAs");
    sessionStorage.removeItem("xWtyHBinsFSGcOAMsdOAfQmcnKAs");

    sessionStorage.clear();
    router.push({name: 'sin'});
}   

export default obliviate