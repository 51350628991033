<template>
    <post-login-nav />
    <side-bar />
    <div class="container">
      <nav class="transparent">
          <div class="nav-wrapper">
              <form>
                  <div class="input-field">
                      <input @keyup="searchMonitor()" id="search" type="search" required v-model="assign_search_param" list="search_output" placeholder="Registration Number" autocomplete="off">
                      <label class="label-icon" for="search"><i class="material-icons">search</i></label>
                      <i class="material-icons">close</i>
                  </div>
              </form>
          </div>
      </nav>

      <div v-if="searchLoading" style="margin-top: 14px; margin-bottom: 14px;" class="center">
          <circular-preloader />
      </div>
      <div v-else>
          <div style="margin-top: 14px;">
              <div v-if="searchUsers.length">
                  <div v-for="voter in searchUsers" :key="voter.inviteID">
                      <ul class="collection" style="border-radius: 7px; margin-top: 7px; margin-bottom: 7px;">
                        <li class="collection-item">
                          <div v-if="voter.expired == 1">
                            <span class="green-text text-darken-4"><i class="tiny right material-icons green-text text-darken-4">verified</i> {{ voter.email }} {{voter.title}}</span>
                          </div>
                          <div v-else>
                            <span class="red-text text-darken-4"><i class="tiny right material-icons red-text text-darken-4">info</i> {{ voter.email }} {{voter.title}}</span>
                          </div>
                          
                        </li>
                      </ul>
                  </div>
              </div>
              <div v-else>
                  <p class="center red-text text-darken-4">{{searchNegativeResults}}</p>
              </div>
          </div>
      </div>
    </div>
    <global-footer />
</template>

<script>
import PostLoginNav from '@/components/PostLoginNav.vue'
import SideBar from '@/components/SideBar.vue'
import GlobalFooter from '@/components/GlobalFooter.vue'
import CircularPreloader from '@/components/CircularPreloader.vue'
import axios from "axios";
import M from "materialize-css";

export default {
  components: { PostLoginNav, SideBar, GlobalFooter, CircularPreloader, },
  data(){
    return{
      assign_search_param: null,
      searchUsers: [],
      addedLogs: [],
      searchNegativeResults: null,
      searchLoading: false,
      logNegativeResults: null,
      logProgress: false,
      authToken: localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs"),
      godView: 'HOPSxJlnactLG6dWhMYjU',
    }
  },
  created(){
    document.title = this.$route.meta.title;
  },
  mounted(){
    //this.$router.push({name: 'router-dash'})
    this.isGodView();
  },
  methods: {
    searchMonitor(){
      if (this.assign_search_param === "" || this.assign_search_param === null || this.assign_search_param.value === 0) {
        //alert("Empty Field - Registration Number");
        this.searchUsers = [];
        this.searchNegativeResults = null;
      }else{
        this.Mojojojo();
      }
    },
    async Mojojojo(){
      this.searchLoading = true;
      let data = new FormData();
      data.append("tsi", this.assign_search_param);
      data.append("tdi", window.btoa(this.authToken));

      try {
        const response = await axios.post("https://a.ivoting.co.ke/AhzuJAnDhfIkS/verSearch", data);
        let trueCondition = response.data.error;
        let returnMessage = response.data.message;
        if (trueCondition === false) {
          this.searchLoading = false;
          this.searchUsers = response.data.thread;
        }else{
          this.searchLoading = false;
          this.searchUsers = [];
          this.searchNegativeResults = returnMessage; 
        }
      } catch (error) {
        this.searchLoading = false;
        this.searchUsers = [];
        this.searchNegativeResults = 'Network Error. Try Again'
      }
    },
    isGodView(){
      let currentUser = window.atob(window.atob(this.authToken));

      if (currentUser != this.godView) {
        this.returnToDash();
      }
    },
    returnToDash(){
      this.$router.push({name: "router-dash"});
    },
  }

}
</script>

<style>

</style>