<template>
    <section>
            <nav class="transparent" style="border-radius: 7px;">
                <div class="nav-wrapper">
                    <form>
                        <div class="input-field">
                            <input @keyup="searchMonitor()" id="search" type="search" required v-model="assign_search_param" list="search_output" placeholder="Poll Name" autocomplete="off">
                            <label class="label-icon" for="search"><i class="material-icons">search</i></label>
                            <i class="material-icons">close</i>
                        </div>
                    </form>
                </div>
            </nav>

            <div v-if="searchLoader" style="margin-top: 14px; margin-bottom: 14px;" class="center">
                <circular-preloader />
            </div>
            <div v-else>
                <div style="margin-top: 14px;">
                    <div v-if="searchData.length">
                        <div v-for="poll in searchData" :key="poll">
                            <ul class="collection" style="border-radius: 7px; margin-top: 7px; margin-bottom: 7px;">
                                <li class="collection-item hoverable">
                                    <router-link :to="{name: 'poll', params: {pageValue: poll.x}}">
                                        <span class="card-title red-text text-darken-4">{{poll.p}}</span><span class="black-text"> - {{poll.c}}</span>
                                    </router-link>
                                </li>

                            </ul>
                        </div>
                    </div>
                    <div v-else>
                        <p class="center red-text text-darken-4">{{searchMessage}}</p>
                    </div>
                </div>
            </div>
        </section>
</template>

<script>
import axios from 'axios'
import CircularPreloader from './CircularPreloader.vue';
export default {
    components: { CircularPreloader, },
    data(){
        return {
            authToken: localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs"),
            assign_search_param: null,
            searchLoader: false,
            searchMessage: null,
            searchData: [],
        }
    },
    methods: {
        searchMonitor(){
            if (this.assign_search_param === "" || this.assign_search_param === null || this.assign_search_param.value === 0) {
                this.searchLoader = false;
                this.searchData = [];
                this.searchMessage = null;
            }else{
                this.searchApply();
            }
        },
        async searchApply(){
            this.searchLoader = true;
            let data = new FormData();
            data.append("sp", this.assign_search_param);
            data.append("sk", window.btoa(this.authToken));

            try {
                const response = await axios.post("https://a.ivoting.co.ke/AhzuJAnDhfIkS/psch", data)
                let returnCondition = response.data.error;
                let returnMessage = response.data.message;

                if (returnCondition === false) {
                    this.searchLoader = false;
                    this.searchData = response.data.th;
                }else{
                    this.searchLoader = false;
                    this.searchMessage = returnMessage; 
                    this.searchData = [];
                }
            } catch (error) {
                this.searchLoader = false;
                this.searchMessage = 'Network Error. Try Again'; 
                this.searchData = [];
                console.log(error);
            }
        },
    }
}
</script>

<style>

</style>