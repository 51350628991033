<template>
    <div class="row">
        <div class="col s12 m2 l2"></div>
        <div class="col s12 m8 l8">
            <div class="card-panel rounded">
                <div class="card-content">
                    <p class="center-align"><img src="@/assets/rs_sign_in.svg" style="width: 152px; height: 152px;"/></p>
                    <form>
                        <div class="input-field">
                            <input id="user_name" type="text" class="validate" required autocomplete="off" v-model="sin_uname">
                            <label for="user_name" class="red-text text-darken-4">Username</label>
                        </div>
                        <div class="input-field">
                            <input id="user_pass" type="password" class="validate" required autocomplete="off" v-model="sin_pass">
                            <label for="user_pass" class="red-text text-darken-4">Password</label>
                        </div>

                        <div @click.prevent="togglePassword()" class="right" style="margin-bottom:0px; margin-top: -21px; cursor: pointer; text-decoration: none;">
                            <div v-if="passwordStatus" style="cursor: pointer;">
                                <p id="user_pass_toggle">Hide Password</p>
                            </div>
                            <div v-else style="cursor: pointer;">
                                <p id="user_pass_toggle">Show Password</p>
                            </div>
                        </div>
                        <br><br>
                        <div>
                            <div class="center" v-if="progress">
                                <circular-preloader />
                            </div>
                            <div v-else>
                                <button class="btn red darken-4 white-text btn-rounded full" @click.prevent="Zhuli">Sign In</button>
                            </div>
                        </div>
                        
                        <div style="margin-bottom: 28px; margin-top: 10px;">
                            <p class="left"><router-link class="red-text text-darken-4" :to="{name: 'sup'}">Sign Up</router-link></p>
                            <p class="right"><router-link class="red-text text-darken-4" :to="{name: 'fopa'}">Forgot Password</router-link></p>
                        </div>
                        <br>
                    </form>
                </div>
            </div>
        </div>
        <div class="col s12 m2 l2"></div>
    </div>
</template>

<script>
    import axios from "axios"
    import M from 'materialize-css';
    import CircularPreloader from "./CircularPreloader.vue";

    export default {
        props: ['returnKey', 'returnPage'],
        data(){
            return{
                progress: false,
                sin_uname: null,
                sin_pass: null,
                encString: null,
                sessID: null,
                accessLoader: false,
                delim: window.btoa('==='),
                passwordStatus: false,
            }
        },
        mounted(){
            // console.log('return page - ' + this.returnPage);
        },
        methods: {
            Zhuli(){
                if (this.sin_uname === "" || this.sin_uname === null || this.sin_uname.value === 0) {
                    alert("Empty Field - Username");
                }else if (this.sin_pass === "" || this.sin_pass === null || this.sin_pass.value === 0) {
                    alert("Empty Field - Password");
                }else{
                    this.progress = true;
                    this.Meeyuki();
                }
            },
            async Meeyuki(){
                let data = new FormData();
                data.append("sfu", this.sin_uname);
                data.append("sfp", this.sin_pass);
                let encPage = window.btoa(this.returnPage);
                let combinedKey = this.returnKey+this.delim+encPage;
                data.append("sfa", window.btoa(combinedKey));
                try {
                    const response = await axios.post("https://a.ivoting.co.ke/AhzuJAnDhfIkS/sin", data)
                    let returnCondition = response.data.rr;
                    let returnMessage = response.data.gs;
                    this.encString = response.data.zd;
                    this.sessID = response.data.zx;

                    if (returnCondition === false) {
                        this.progress = false;
                        localStorage.setItem("xWtyHBansFSGcOAMsdOAfQmcnKAs", this.encString);
                        sessionStorage.setItem("xWtyHBansFSGcOAMsdOAfQmcnKAs", this.encString);

                        localStorage.setItem("xWtyHBinsFSGcOAMsdOAfQmcnKAs", this.sessID);
                        sessionStorage.setItem("xWtyHBinsFSGcOAMsdOAfQmcnKAs", this.sessID);
                        //console.log("items set")
                        this.redirectToDashboard();
                    }else {
                        this.progress = false;
                        M.toast({ html: '' + returnMessage });
                    }
                } catch (error) {
                    //console.log(error);
                    this.progress = false;
                    M.toast({ html: 'Network Error. Try Again'});
                }
            },
            togglePassword(){
                var x = document.getElementById("user_pass");
                if (x.type === "password") {
                    this.passwordStatus = true;
                    var text = document.getElementById("user_pass_toggle").innerHTML = "Hide Password";
                    x.type = "text";
                } else {
                    this.passwordStatus = false;
                    var text = document.getElementById("user_pass_toggle").innerHTML = "Show Password";
                    x.type = "password";
                }
            },
            redirectToDashboard(){
                //console.log("redirect")
                this.$router.push({name: "router-dash"});
            },
            keyChecker(){
                if (this.returnKey === null || this.returnKey === 0 || this.returnKey === "") {
                    M.toast({html: 'ErrCode 2390. Contact The System Administrator.'});
                }
            },
        },
        components: { CircularPreloader, }
    }
</script>

<style scoped>
    .rounded{
        border-radius: 14px;
    }
    .full {
        width: 100%;
    }

    .btn-rounded {
        border-radius: 21px;
    }
</style>