import router from '../router/index.js'
import axios from 'axios';
import M from 'materialize-css'
import obliviate from './obliviate.js';

async function routerLogout() {
    const userID = localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs");
    const sessID = localStorage.getItem("xWtyHBinsFSGcOAMsdOAfQmcnKAs");

    if (userID === null || sessID === null) {
        obliviate();
        //console.log('null');
    }else if (userID === "" || sessID === "") {
        obliviate();
        //console.log('empty');
    }else if (userID === 0 || sessID === 0) {
        obliviate();
        //console.log('0');
    } else {
        let data = new FormData();
        data.append("lp", userID);
        data.append("ls", sessID);

        try {
            const response = await axios.post('https://a.ivoting.co.ke/AhzuJAnDhfIkS/sout', data);
            let returnCondition = response.data.error;
            let returnMessage = response.data.message;

            if (returnCondition === false) {
                obliviate();
                // console.log('lout s');
            } else {
                M.toast({html: returnMessage});
                obliviate();
                // console.log('lout f');
            }
        } catch (error) {
            M.toast({html: 'Network Error. Try Again'});
            obliviate();
            // console.log(error);
        }
    }
}

export default routerLogout