<template>
    <div class="card rounded">
        <div class="card-content">
            <span class="card-title red-text text-darken-4 center">Votes</span>
            <div v-if="voteLoader" class="center">
                <p><small>-</small></p>
            </div>
            <div v-else>
                <div v-if="voteData.length">
                    <div v-for="voteStat in voteData" :key="voteStat">
                        <p class="center">{{ voteStat.vc }}</p>
                    </div>
                </div>
                <div v-else>
                    <p class="center red-text text-darken-4"><small>{{ voteMessage }}</small></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    props: {
        poll: {
            type: String
        }
    },
    data(){
        return{
            authToken: localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs"),
            voteLoader: true,
            voteMessage: null,
            voteData: [],
        }
    },
    mounted(){
        this.voteDeets();
    },
    methods: {
        async voteDeets(){
            let data = new FormData();
            data.append('ik', this.authToken);
            data.append('it', this.poll);

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AhzuJAnDhfIkS/ps-vt', data)
                let returnCondition = response.data.error
                let returnMessage = response.data.message

                if (returnCondition === false) {
                    this.voteLoader = false
                    this.voteData = response.data.th
                    this.voteMessage = null
                } else {
                    this.voteLoader = false
                    this.voteData = [],
                    this.voteMessage = returnMessage
                }
            } catch (error) {
                this.voteLoader = false
                this.voteData = [],
                this.voteMessage = 'NE'
            }
        },
    }
}
</script>

<style>

</style>