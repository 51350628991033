<template>
    <div class="card rounded">
        <div class="card-content">
            <span class="card-title red-text text-darken-4 center">Links</span>
            <div v-if="inviteLoader" class="center">
                <p><small>-</small></p>
            </div>
            <div v-else>
                <div v-if="inviteData.length">
                    <div v-for="linkStat in inviteData" :key="linkStat">
                        <p class="center"><small>{{ linkStat.bt }}</small> / {{ linkStat.ke }}</p>
                    </div>
                </div>
                <div v-else>
                    <p class="center red-text text-darken-4"><small>{{ inviteMessage }}</small></p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import axios from 'axios'
export default {
    props: {
        poll: {
            type: String
        }
    },
    data(){
        return {
            authToken: localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs"),
            inviteMessage: null,
            inviteLoader: true,
            inviteData: [],
        }
    },
    mounted(){
        this.loadInviteData();
    },
    methods: {
        async loadInviteData(){
            let data = new FormData();
            data.append('ik', this.authToken);
            data.append('it', this.poll);

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AhzuJAnDhfIkS/ps-l', data)
                let returnCondition = response.data.error
                let returnMessage = response.data.message

                if (returnCondition === false) {
                    this.inviteLoader = false
                    this.inviteData = response.data.th
                    this.inviteMessage = null
                } else {
                    this.inviteLoader = false
                    this.inviteData = [],
                    this.inviteMessage = returnMessage
                }
            } catch (error) {
                this.inviteLoader = false
                this.inviteData = [],
                this.inviteMessage = 'NE'
            }
        },
    }
}
</script>

<style>

</style>