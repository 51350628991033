<template>
  <pre-login-nav />
  <div class="container" style="margin-top: 21px;">

    <div v-if="initLoading" class="center">
      <circular-preloader />
    </div>
    <div v-else>
      <div v-if="initStatus == 1">
        <sign-in-card :returnKey="initToken" :returnPage="initPage" />
      </div>
      <div v-else>
        <p class="center red-text text-darken-4">{{ initMessage }}</p>
      </div>
    </div>
  </div>
  <global-footer />
</template>

<script>
  
  import PreLoginNav from '@/components/PreLoginNav.vue';
  import GlobalFooter from '@/components/GlobalFooter.vue';
  import SignInCard from '@/components/SignInCard.vue';
  import CircularPreloader from '@/components/CircularPreloader.vue';
  import axios from 'axios'

  export default {
    created(){
      document.title = this.$route.meta.title;
    },
    beforeMount(){
      
    },
    mounted(){
      this.checkForUser();
      this.permissionCheck();
    },
    data(){
      return{
        localUsr: localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs"),
        initLoading: true,
        initStatus: null,
        initMessage: 'Contact The System Administrator',
        initPage: 'uJAndiBVA-NBGSbcuN-iNDbk',
        initToken: null,
        initKey: null,
      }
    },
    methods: {
      checkForUser(){
        if (this.localUsr === "" || this.localUsr === 0 || this.localUsr === null) {
        }else {
          this.redirectToDashboard();
        }
      },
      redirectToDashboard(){
        this.$router.push({name: "router-dash"});
      },
      async permissionCheck(){
        let data = new FormData();
        data.append("AunINSdNCoHbd", window.btoa(this.initPage));

        try {
          const response = await axios.post("https://a.ivoting.co.ke/AhzuJAnDhfIkS/sfae", data)
          let returnCondition = response.data.rr;
          let returnMessage = response.data.sg;

          if (returnCondition === false) {
            this.initLoading = false;
            this.initStatus = 1;
            this.initToken = response.data.th;
            this.initMessage = null;
          } else {
            this.initLoading = false;
            this.initStatus = 0;
            this.initMessage = returnMessage;
          }
        } catch (error) {
          this.initLoading = false;
          this.initStatus = 0,
          this.initMessage = 'Network Error. Try Again'
        }
      }
    },
    components: { PreLoginNav, GlobalFooter, SignInCard, CircularPreloader },
  }
</script>

<style>

</style>
