<template>
    <post-login-nav />
    <side-bar />
    <div class="container">
        <section>
            <nav class="transparent">
                <div class="nav-wrapper">
                    <form>
                        <div class="input-field">
                            <input @keyup="searchMonitor()" id="search" type="search" required v-model="assign_search_param" list="search_output" placeholder="Registration Number" autocomplete="off">
                            <label class="label-icon" for="search"><i class="material-icons">search</i></label>
                            <i class="material-icons">close</i>
                        </div>
                    </form>
                </div>
            </nav>

            <div v-if="searchLoading" style="margin-top: 14px; margin-bottom: 14px;" class="center">
                <circular-preloader />
            </div>
            <div v-else>
                <div style="margin-top: 14px;">
                    <div v-if="searchUsers.length">
                        <div v-for="voter in searchUsers" :key="voter">
                            <div v-if="voter.asp">
                                <ul class="collection" style="border-radius: 7px; margin-top: 7px; margin-bottom: 7px;">
                                    <li class="collection-item">
                                        <span class="card-title green-text text-darken-4">{{voter.asn}}</span><span class="black-text"> - {{voter.ass}} - {{voter.asr}}</span>
                                    </li>
                                </ul>
                            </div>
                            <div v-else>
                                <ul class="collection" style="border-radius: 7px; margin-top: 7px; margin-bottom: 7px;">
                                    <li class="collection-item hoverable">
                                        <router-link :to="{name: 'vassin', params: {vAS: voter.asz}}">
                                            <span class="card-title red-text text-darken-4">{{voter.asn}}</span><span class="black-text"> - {{voter.ass}} - {{voter.asr}}</span>
                                        </router-link>
                                    </li>

                                </ul>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <p class="center red-text text-darken-4">{{searchNegativeResults}}</p>
                    </div>
                </div>
            </div>
        </section>

        <section>
            <div v-if="pageLoading" class="center">
                <circular-preloader />
            </div>
            <div v-else>
                
                <div v-if="pageData.length">
                    <table class="striped hightlight">
                        <thead>
                            <tr class="red-text text-darken-4">
                                <td>Name</td>
                                <td>Tel</td>
                                <td>Time</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="instance in pageData" :key="instance">
                                <td>{{instance.asn}}</td>
                                <td>{{instance.asp}}</td>
                                <td>{{instance.ast}}</td>
                            </tr>
                        </tbody>
                    </table>

                    <section>
                        <ul class="pagination center">
                            <li v-for="index in pageTotal" :key="index" style="margin-right: 10px; margin-left: 10px;">
                                <div v-if="index === pageCurrent">
                                    <span class="red-text text-darken-4" style="text-decoration: none; cursor: pointer; text-decoration: line-through;" @click.prevent="loadRecords(index)">{{ index }}</span>
                                </div>
                                <div v-else>
                                    <span class="" style="text-decoration: none; cursor: pointer;" @click.prevent="loadRecords(index)">{{ index }}</span>
                                </div>
                            </li>
                        </ul>
                    </section>

                </div>

                <div v-else>
                    <p class="center red-text text-darken-4">{{ pageMessage }}</p>
                </div>
            </div>
        </section>
    </div>
    <global-footer />
</template>

<script>
import PostLoginNav from '@/components/PostLoginNav.vue'
import SideBar from '@/components/SideBar.vue'
import GlobalFooter from '@/components/GlobalFooter.vue'
import CircularPreloader from '@/components/CircularPreloader.vue'
import axios from 'axios'

export default {
    components: { PostLoginNav, SideBar, GlobalFooter, CircularPreloader },
    props: {
        pageValue: {
            default: 1,
            type: String
        }
    },
    data(){
        return {
            assign_search_param: null,
            searchUsers: [],
            addedLogs: [],
            searchNegativeResults: null,
            searchLoading: false,
            pageLoading: true,
            pageMessage: null,
            pageData: [],
            pageTotal: null,
            pageCurrent: null,
            authToken: localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs"),
        }
    },
    created(){
        document.title = this.$route.meta.title;
    },
    mounted(){
        this.loadRecords(this.pageValue);
    },
    methods: {
        async loadRecords(pageValue){
            this.pageLoading = true;
            let data = new FormData();
            data.append("lgi", window.btoa(this.authToken));
            data.append("lgt", pageValue);
            
            try {
                const response = await axios.post("https://a.ivoting.co.ke/AhzuJAnDhfIkS/pagiTwo", data)
                let returnCondition = response.data.error;
                let returnMessage = response.data.message;

                if (returnCondition === false) {
                    this.pageLoading = false;
                    this.pageData = response.data.thread;
                    this.pageTotal = response.data.pn;
                    this.pageCurrent = response.data.pc;
                } else {
                    this.pageLoading = false;
                    this.pageData = [];
                    this.pageMessage = returnMessage;
                }
            } catch (error) {
                this.pageLoading = false;
                this.pageData = [];
                this.pageMessage = 'Network Error. Try Again';
            }
        },
        searchMonitor(){
            if (this.assign_search_param === "" || this.assign_search_param === null || this.assign_search_param.value === 0) {
                //alert("Empty Field - Registration Number");
                this.searchLoading = false;
                this.searchUsers = [];
                this.searchNegativeResults = null;
            }else{
                this.searchApply();
            }
        },
        async searchApply(){
            this.searchLoading = true;
            let data = new FormData();
            data.append("st", this.assign_search_param);
            data.append("sa", window.btoa(this.authToken));

            try {
                const response = await axios.post("https://a.ivoting.co.ke/AhzuJAnDhfIkS/asSearch", data)
                let returnCondition = response.data.error;
                let returnMessage = response.data.message;

                if (returnCondition === false) {
                    this.searchLoading = false;
                    this.searchUsers = response.data.thread;
                }else{
                    this.searchLoading = false;
                    this.searchNegativeResults = returnMessage; 
                    this.searchUsers = [];
                    // M.toast({html: ''+returnMessage});
                }
            } catch (error) {
                this.searchLoading = false;
                this.searchNegativeResults = 'Network Error. Try Again'; 
                this.searchUsers = [];
            }
        },
    }

}
</script>

<style scoped>
    
</style>