<template>
    <div class="row">
        <div class="col s12 m2 l2"></div>
        <div class="col s12 m8 l8">
            <div class="card rounded">
                <div class="card-content">
                    <p class="center-align"><img src="@/assets/rs_forgot_password.svg" style="width: 152px; height: 152px;"/></p>
                    <form>
                        <div class="input-field">
                            <input id="user_name" type="text" class="validate" required autocomplete="off" v-model="fp_uname">
                            <label for="user_name" class="red-text text-darken-4">Username</label>
                        </div>

                        <div>
                            <div class="center" v-if="progress">
                                <circular-preloader />
                            </div>
                            <div v-else>
                                <button class="btn red darken-4 white-text btn-rounded full" v-on:click.prevent="Zhuli">Recover Password</button>
                            </div>
                        </div>

                        <div style="margin-top: 10px;">
                            <p class="center"><router-link :to="{name: 'sin'}"><span>Sign In</span></router-link></p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col s12 m2 l2"></div>
    </div>
</template>

<script>
    import M from 'materialize-css'
    import axios from "axios"
    import CircularPreloader from './CircularPreloader.vue'

    export default {
        props: ['pval', 'pvalp'],
        data(){
            return {
                progress: false,
                fp_uname: null
            }
        },
        created(){
            this.speakFriendAndEnter();
        },
        methods: {
            Zhuli(){
                if (this.fp_uname === "" || this.fp_uname === null || this.fp_uname.value === 0) {
                    alert("Empty Field - Username");
                }else {
                    this.progress = true;
                    this.lordFarquad();
                }
            },
            async lordFarquad(){
                let data = new FormData();
                data.append("fpru", window.btoa(this.fp_uname));
                data.append("fprn", this.pval);
                data.append("fprp", window.btoa(this.pvalp));
                try {
                    const response = await axios.post("https://a.ivoting.co.ke/AhzuJAnDhfIkS/fps", data);
                    let trueCondition = response.data.error;
                    let successMessage = response.data.message;
                    if (trueCondition === false) {
                        this.progress = false;
                        M.toast({ html: '' + successMessage });
                        //this.resetForm();
                        this.resetError();
                    }else {
                        this.progress = false;
                        M.toast({ html: '' + successMessage });
                        this.resetError();

                    }
                } catch (error) {
                    this.progress = false;
                    M.toast({ html: error + ' Network Error. Try Again.'});
                    this.resetError();
                }
            },
            resetForm() {
                this.fp_uname = "";
                this.redirectHome();
            },
            resetError(){
                this.fp_uname = "";
            },
            redirectHome() {
                this.$router.push({
                    name: "sin"
                });
            },
            speakFriendAndEnter(){
                if (this.pval === null || this.pval === 0 || this.pval === "") {
                    this.redirectHome();
                }
            }
        },
        components: { CircularPreloader, }
    }
</script>

<style scoped>
    .rounded{
        border-radius: 14px;
    }
    .full {
        width: 100%;
    }

    .btn-rounded {
        border-radius: 21px;
    }
</style>