<template>
    <footer class="transparent">
        <p class="center-align">Copyright &copy; 2019 - {{ currentYear }}. <span class="red-text text-darken-4">{{ targetProduct }}</span>. A <span class="red-text text-darken-4">{{ targetCompany }}</span> Product.</p>
    </footer>
</template>

<script>
export default {
    methods: {
        dateTimeVals(){
            const today = new Date();
            this.currentYear = today.getFullYear();
        }
    },
    data(){
        return{
            currentYear: null,
            targetProduct: this.$store.state.storeProductName,
            targetCompany: this.$store.state.storeCompany,
        }
    },
    beforeMount(){
        this.dateTimeVals();
    },
}
</script>

<style>

</style>