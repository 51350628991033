<template>
    <pre-login-nav />
    <div class="container">
        <div v-if="initLoader" class="center">
            <circular-preloader />
        </div>
        <div v-else>
            <div v-if="initStatus == 1">
                <forgot-password-card :pval="initSample" :pvalp="initPage"/>
            </div>
            <div v-else>
                <p class="center red-text text-darken-4">{{ initMessage }}</p>
            </div>
        </div>
    </div>
    <global-footer />
</template>

<script>
    import PreLoginNav from '@/components/PreLoginNav.vue'
    import ForgotPasswordCard from '@/components/ForgotPasswordCard.vue'
    import CircularPreloader from '@/components/CircularPreloader.vue'
    import axios from 'axios'
    import GlobalFooter from '@/components/GlobalFooter.vue'

    export default {
        components: { PreLoginNav, ForgotPasswordCard, CircularPreloader, GlobalFooter },
        created(){
            document.title = this.$route.meta.title;
        },
        data(){
            return{
                initPage: 'uJAndiBVA-NBGSbcoN-iNDbk',
                initLoader : true,
                initMessage: 'Contact The System Administrator.',
                initStatus: 0,
                initToken: null,
                initSample: null
            }
        },
        methods: {
            async PortKey(){
                let data = new FormData();
                data.append("InfhIBDnkiNBZx", this.initPage);

                try {
                    const response = await axios.post('https://a.ivoting.co.ke/AhzuJAnDhfIkS/sfae-pr', data)
                    let returnCondition = response.data.error;
                    let returnMessage = response.data.message;

                    if (returnCondition === false) {
                        this.initLoader = false;
                        this.initStatus = 1
                        this.initSample = response.data.null;
                    } else {
                        this.initLoader = false;
                        this.initMessage = returnMessage;
                        this.initSample = null;
                    }
                } catch (error) {
                    this.initLoader = false;
                    this.initMessage = returnMessage;
                    this.initSample = null;
                }
            }
        },
        beforeMount(){
            this.PortKey();
        }
    }
</script>

<style>

</style>