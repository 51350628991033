<template>
    <post-login-nav />
    <side-bar />
    <div class="container">
        <div v-if="resultLoader" class="center">
            <circular-preloader />
        </div>
        <div v-else>
            <div v-if="resultData.length">
                <blockquote><span>{{ resultPoll}}</span> / <span class="red-text text-darken-4">{{ resultTotal }}</span> Votes Cast</blockquote>
                <table class="highlight striped">
                    <thead class="red-text text-darken-4">
                        <tr>
                            <td>Aspirant</td>
                            <td>Votes</td>
                            <td>%</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="aspirant in resultData" :key="aspirant">
                            <td>{{ aspirant.c }}</td>
                            <td>{{ aspirant.v }}</td>
                            <td>{{ (((+aspirant.v) / resultTotal) * 100).toFixed(2) }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div v-else>
                <p class="center red-text text-darken-4">{{ resultMessage }}</p>
            </div>
        </div>
    </div>
    <global-footer />
</template>

<script>
import PostLoginNav from '@/components/PostLoginNav.vue'
import SideBar from '@/components/SideBar.vue'
import GlobalFooter from '@/components/GlobalFooter.vue'
import CircularPreloader from '@/components/CircularPreloader.vue'
import axios from 'axios'

export default {
    components: { SideBar, PostLoginNav, GlobalFooter, CircularPreloader },
    props: {
        pageValue: {
            type: String
        }
    },
    data(){
        return{
            authToken: localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs"),
            resultLoader: true,
            resultMessage: null,
            resultData : [],
            resultTotal: null,
            resultPoll: null,
        }
    },
    methods: {
        async quickDraw(){
            let data = new FormData();
            data.append('rm_p', this.pageValue);
            data.append('rm_k', this.authToken);

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AhzuJAnDhfIkS/rm', data)
                let returnCondition = response.data.error
                let returnMessage = response.data.message

                if (returnCondition === false) {
                    this.resultLoader = false
                    this.resultMessage = null
                    this.resultData = response.data.thread
                    this.resultTotal = response.data.z
                    this.resultPoll = response.data.p
                } else {
                    this.resultLoader = false
                    this.resultData = []
                    this.resultMessage = returnMessage
                }
            } catch (error) {
                this.resultLoader = false
                this.resultData = []
                this.resultMessage = 'Network Error. Try Again'
                //console.log(error);
            }
        },
    },
    mounted(){
        this.quickDraw();
    },
    created(){
        document.title = this.$route.meta.title;
    },
}
</script>

<style>
        
</style>