<template>
    <post-login-nav />
    <side-bar />
    <div class="container-fluid">
        <div v-if="godView === authToken">
            <dashboard-analytics />
        </div>        
        <dashboard-section />
    </div>
    <global-footer />
</template>

<script>
    import GlobalFooter from "@/components/GlobalFooter.vue";
    import PostLoginNav from '@/components/PostLoginNav.vue';
    import SideBar from '@/components/SideBar.vue';
    import CircularPreloader from "@/components/CircularPreloader.vue";
    import DashboardAnalytics from '@/components/DashboardAnalytics.vue';
import DashboardSection from '@/components/DashboardSection.vue';

    export default {
        data(){
            return {
                godView: 'HOPSxJlnactLG6dWhMYjU',
                authToken: window.atob(window.atob(localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs"))),
            }
        },
        created(){
            document.title = this.$route.meta.title;
        },
        components: { GlobalFooter, SideBar, PostLoginNav, CircularPreloader, DashboardAnalytics, DashboardSection },
    }
</script>

<style>
    
</style>