<template>
    <ul class="sidenav" id="mobile-links">
        <div style="margin-top: 28px;">
            <li class="center">
                <ul>
                    <li><i class="large material-icons red-text text-darken-4">account_circle</i></li>
                    <li style="margin-top: -21px;"><span class="flow-text">{{ fromStoreName }}</span></li>
                    <li style="margin-top: -21px;"><span class="red-text text-darken-4">@{{ fromStoreUsername }}</span></li>

                    <li class="center">
                        <div v-if="isDarkMode === false">
                            <span><i @click.prevent="toggle(!isDarkMode)" class="material-icons red-text text-darken-4" style="cursor: pointer;">light_mode</i></span>
                        </div>
                        <div v-else>
                            <span><i @click.prevent="toggle(!isDarkMode)" class="material-icons red-text text-darken-4" style="cursor: pointer;">dark_mode</i></span>
                        </div>
                    </li>


                    <!-- <li class="center">
                        <div v-if="isDark === false">
                            <span><i @click.prevent="toggleDark()" class="material-icons red-text text-darken-4" style="cursor: pointer;">light_mode</i></span>
                        </div>
                        <div v-else>
                            <span><i @click.prevent="toggleDark()" class="material-icons red-text text-darken-4" style="cursor: pointer;">dark_mode</i></span>
                        </div>
                    </li> -->
                </ul>
            </li>
            <br>
            <li style="cursor: pointer;"> <router-link :to="{name: 'profile'}" class="sidenav-close blue-text text-darken-4"><i class="material-icons blue-text text-darken-4">person</i> Account</router-link></li>
            <li style="cursor: pointer;"><a class="sidenav-close red-text text-darken-4 modal-trigger" href="#sign-out-modal"><i class="material-icons red-text text-darken-4">logout</i> Sign Out</a></li>
            <br>
            <div class="divider"></div>
            <li class="center">
                <span class=""><small>Copyright &copy; 2019 - {{ currentYear }}. <span class="red-text text-darken-4">{{targetProduct}}</span></small></span>
            </li>
        </div>
    </ul>

    <div id="sign-out-modal" class="modal">
        <div class="modal-content" style="margin-bottom: 21px;">
            <p class="center">Do you want to Sign Out?</p>
            <div class="container">
                <div class="row">
                    <div class="col sm6 m6 l6">
                        <button class="modal-close btn red darken-4 white-text" style="width: 100%; border-radius: 5px;">Cancel</button>
                    </div>
                    <div class="col sm6 m6 l6">
                        <button id="lob" class="btn green darken-4 white-text" style="width: 100%; border-radius: 5px;" @click.prevent="doTheThingBoth">Proceed</button>
                    </div>
                </div>
            </div>
        </div>
  </div>
</template>

<!-- <script setup>
    import { useDark, useToggle } from '@vueuse/core'
    const isDark = useDark()
    const toggleDark = useToggle(isDark)
</script> -->

<script>
    import routerLogout from '@/functions/routerLogout';
    import M from 'materialize-css';
    export default {
        data(){
            return{
                targetProduct: this.$store.state.storeProductName,
                currentYear: null,
                isDarkMode: false,
            }
        },  
        methods: {
            doTheThingBoth(){
                document.getElementById('lob').disabled = true;
                routerLogout();
            },
            dateTimeVals(){
                const today = new Date();
                this.currentYear = today.getFullYear();
            },
            toggle(payload){
                this.isDarkMode = payload;
                document.documentElement.className = payload;
                localStorage.setItem('theme', payload);
            }
        },
        mounted(){
            M.AutoInit();
        },
        beforeMount(){
            //this.$store.dispatch('decrypt');
            this.$store.dispatch('getUD');
            this.dateTimeVals();
        },
        computed: {
            fromStoreName(){
                return this.$store.state.storeName;
            },
            fromStoreUsername(){
                return this.$store.state.storeUname;
            }
        }
    }
</script>

<style>

</style>