<template>
    <pre-login-nav />
    <div class="container" style="margin-top: 63px;">
        <h4 class="center-align">Error 404.</h4>
    </div>
    <global-footer />
</template>

<script>
import GlobalFooter from "@/components/GlobalFooter.vue"
import PreLoginNav from '@/components/PreLoginNav.vue'
import routerLogout from "@/functions/routerLogout";

export default {
    created(){
      document.title = this.$route.meta.title;
    },
    mounted(){
        routerLogout();
    },
    components: { GlobalFooter, PreLoginNav },
}
</script>

<style>

</style>