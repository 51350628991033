<template>
    <nav class="transparent z-depth-0" style="margin-bottom: 14px;">
        <div class="nav-wrapper" style="margin-right: 5px; margin-left: 5px;">
            <a href="#" class="brand-logo red-text text-darken-4 center">
                <router-link :to="{name: 'sin'}"><img src="../assets/logo.png" style="width: 49px; height: 49px; margin-left: 5px; margin-top: 10px;"/></router-link>
            </a>

            <!-- <button @click.prevent="toggle(!isDarkMode)" class="right btn red darken-4">{{ isDarkMode }}</button> -->

            <!-- <ul class="right">
                <div v-if="isDark === false">
                    <li class="red-text"><i @click.prevent="toggleDark('light')" class="material-icons red-text text-darken-4" style="cursor: pointer;">light_mode</i></li>
                </div>
                <div v-else>
                    <li class="red-text"><i @click.prevent="toggleDark('dark')" class="material-icons red-text text-darken-4" style="cursor: pointer;">dark_mode</i></li>
                </div>
            </ul> -->


            <!-- <ul id="nav-mobile" class="right hide-on-med-and-down">
                <li><router-link tag="li" :to="{ name : 'sin'}">Sign In</router-link></li>
                <li><router-link tag="li" :to="{ name : 'sup'}">Sign Up</router-link></li>
            </ul> -->
        </div>
    </nav>
</template>

<script>
    export default {    
        data(){
            return {
                isDarkMode: false,
            }
        },
        methods: {
            toggle(payload){
                this.isDarkMode = payload;
                document.documentElement.className = payload;
                localStorage.setItem('theme', payload);
            }
        },
    }
</script>

<!-- <script setup>
    import { useDark, useToggle } from '@vueuse/core'
    const isDark = useDark()
    const toggleDark = useToggle(isDark)
</script> -->

<style>
    nav a {
        background-color: transparent !important;
        color: #2c3e50 !important;
    }

    nav a.router-link-exact-active {
        color: #b71c1c !important;
        transition: all 0.5s ease-in-out;
    }

    nav li a:hover{
        font-weight: bold;
        color: #b71c1c !important;
        background-color: transparent !important;
        text-decoration: overline;
        transition: all 0.5s ease-in-out;
    }
</style>