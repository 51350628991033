<template>
    <post-login-nav />
    <side-bar />
    <div class="container">
        <nav class="transparent">
            <div class="nav-wrapper">
                <form>
                    <div class="input-field">
                        <input @keyup="searchMonitor()" id="search" type="search" required v-model="assign_search_param" list="search_output" placeholder="Registration Number" autocomplete="off">
                        <label class="label-icon" for="search"><i class="material-icons">search</i></label>
                        <i class="material-icons">close</i>
                    </div>
                </form>
            </div>
        </nav>

        <div v-if="searchLoading" style="margin-top: 14px; margin-bottom: 14px;" class="center">
            <circular-preloader />
        </div>
        <div v-else>
            <div style="margin-top: 14px;">
                <div v-if="searchUsers.length">
                    <div v-for="voter in searchUsers" :key="voter.id">
                        <div v-if="voter.phone">
                            <ul class="collection" style="border-radius: 7px; margin-top: 7px; margin-bottom: 7px;">
                                <li class="collection-item">
                                    <span class="card-title green-text text-darken-4">{{voter.name}}</span><span class="black-text"> - {{voter.school}} - {{voter.regno}}</span>
                                </li>
                            </ul>
                        </div>
                        <div v-else>
                            <ul class="collection" style="border-radius: 7px; margin-top: 7px; margin-bottom: 7px;">
                                <li class="collection-item hoverable">
                                    <router-link :to="{name: 'vassin', params: {vAS: voter.daaID}}">
                                        <span class="card-title red-text text-darken-4">{{voter.name}}</span><span class="black-text"> - {{voter.school}} - {{voter.regno}}</span>
                                    </router-link>
                                </li>

                            </ul>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <p class="center red-text text-darken-4">{{searchNegativeResults}}</p>
                </div>
            </div>
        </div>

        <section>
            <div v-if="logProgress">
                <div v-if="addedLogs.length">
                    <table class="highlight striped">
                        <thead>
                            <tr class="red-text text-darken-4">
                                <td>Name</td>
                                <td>Tel</td>
                                <td>Time</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="logged in addedLogs" :key="logged.tl_id">
                                <td>{{logged.tl_uname}}</td>
                                <td>{{logged.tl_tel}}</td>
                                <td>{{logged.tl_sentDate}}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div v-else>
                    <p class="center red-text text-darken-4">{{logNegativeResults}}</p>
                </div>
            </div>
            <div v-else class="center">
                <circular-preloader />
            </div>
        </section>
    </div>
    <global-footer />
</template>

<script>
import GlobalFooter from "@/components/GlobalFooter.vue";
import PostLoginNav from '@/components/PostLoginNav.vue';
import SideBar from '@/components/SideBar.vue';
import axios from 'axios';
import M from "materialize-css";
import CircularPreloader from '@/components/CircularPreloader.vue';
import store from '../store/'

export default {
    components: { GlobalFooter, PostLoginNav, SideBar, CircularPreloader },
    created(){
        document.title = this.$route.meta.title;
    },
    mounted(){
        //this.loadStoreData();
        this.returnToDash();
    },
    data(){
        return {
            assign_search_param: null,
            searchUsers: [],
            addedLogs: [],
            searchNegativeResults: null,
            searchLoading: false,
            logNegativeResults: null,
            logProgress: false,
            authToken: localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs"),
        }
    },
    methods: {
        searchMonitor(){
            if (this.assign_search_param === "" || this.assign_search_param === null || this.assign_search_param.value === 0) {
                //alert("Empty Field - Registration Number");
                this.searchLoading = false;
                this.searchUsers = [];
                this.searchNegativeResults = null;
            }else{
                this.searchApply();
            }
        },
        async searchApply(){
            this.searchLoading = true;
            let data = new FormData();
            data.append("st", this.assign_search_param);
            data.append("sa", window.btoa(this.authToken));

            try {
                const response = await axios.post("https://a.ivoting.co.ke/AhzuJAnDhfIkS/asSearch", data)
                let returnCondition = response.data.error;
                let returnMessage = response.data.message;

                if (returnCondition === false) {
                    this.searchLoading = false;
                    this.searchUsers = response.data.user_Data;
                }else{
                    this.searchLoading = false;
                    this.searchNegativeResults = returnMessage; 
                    this.searchUsers = [];
                    // M.toast({html: ''+returnMessage});
                }
            } catch (error) {
                this.searchLoading = false;
                this.searchNegativeResults = 'Network Error. Try Again'; 
                this.searchUsers = [];
            }
        },
        async loadMyAddedLogs(){
            this.logProgress = false;
            let data = new FormData();
            data.append("log_id", this.authToken);
            
            try {
                const response = await axios.post("https://a.ivoting.co.ke/AhzuJAnDhfIkS/asTel", data)
                let returnCondition = response.data.error;
                let returnMessage = response.data.message;

                if (returnCondition === false) {
                    this.logProgress = true;
                    this.addedLogs = response.data.tel_log_data;
                } else {
                    this.logProgress = true;
                    this.addedLogs = [];
                    this.logNegativeResults = returnMessage;
                }
            } catch (error) {
                this.logProgress = true;
                this.addedLogs = [];
                this.logNegativeResults = 'Network Error. Try Again';
            }
        },
        loadStoreData(){
            this.$store.dispatch('getUD');
            this.loadMyAddedLogs();
        },
        returnToDash(){
            this.$router.push({name: "router-dash"});
        },
    }
}
</script>

<style>

</style>