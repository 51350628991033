<template>
    <post-login-nav />
    <side-bar />
    <div class="container">
        <div v-if="pollLoader" class="center">
            <circular-preloader />
        </div>
        <div v-else>
            <div v-if="pollData.length">
                <polls-search />
                <div class="row">
                    <div class="col s12 m6 l4" v-for="poll in pollData" :key="poll">
                        <div class="card rounded">
                            <router-link :to="{name: 'poll', params: {pageValue: poll.pi}}">
                                <div class="card-content">
                                    <h6 class="truncate spec-text">{{ poll.pn }}</h6>
                                    <small class="red-text text-darken-4">{{ poll.pc }}</small>
                                </div>
                            </router-link>
                        </div>
                    </div>
                </div>
                <section>
                    <ul class="pagination center">
                        <li v-for="index in pageTotal" :key="index" style="margin-right: 10px; margin-left: 10px;">
                            <div v-if="index === pageCurrent">
                                <span class="red-text text-darken-4" style="text-decoration: none; cursor: pointer; text-decoration: line-through;" @click.prevent="loadPolls(index)">{{ index }}</span>
                            </div>
                            <div v-else>
                                <span class="" style="text-decoration: none; cursor: pointer;" @click.prevent="loadPolls(index)">{{ index }}</span>
                            </div>
                        </li>
                    </ul>
                </section>
            </div>
            <div v-else class="center">
                <p class="red-text text-darken-4">{{ pollMessage }}</p>
            </div>
        </div>
    </div>
    <global-footer />
</template>

<script>
import PostLoginNav from '@/components/PostLoginNav.vue'
import SideBar from '@/components/SideBar.vue'
import GlobalFooter from '@/components/GlobalFooter.vue'
import CircularPreloader from '@/components/CircularPreloader.vue'
import PollsSearch from '@/components/PollsSearch.vue'
import axios from 'axios'

export default {
    props: {
        pageValue: {
            default: 1,
            type: String
        }
    },
    components: { PostLoginNav, SideBar, GlobalFooter, CircularPreloader, PollsSearch },
    data(){
        return {
            authToken: localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs"),
            pollLoader: true,
            pollMessage: 'Contact The System Administrator.',
            pollData: [],
            pollCount: null,
            pollCurrentPage: null,
            pageTotal: null,
            pageCurrent: null
        }
    },
    created(){
        document.title = this.$route.meta.title;
    },
    mounted(){
        this.loadPolls(this.pageValue);
    },
    methods: {
        async loadPolls(pageValue){
            let data = new FormData();
            data.append('pli', this.authToken);
            data.append('plp', pageValue);

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AhzuJAnDhfIkS/pls', data);
                let returnCondition = response.data.error;
                let returnMessage = response.data.message;

                if (returnCondition === false) {
                    this.pollLoader = false
                    this.pollMessage = null
                    this.pollData = response.data.th
                    this.pageCurrent = response.data.pc
                    this.pageTotal = response.data.pn
                } else {
                    this.pollLoader = false;
                    this.pollData = [],
                    this.pollMessage = returnMessage
                }
            } catch (error) {
                this.pollLoader = false;
                this.pollData = [],
                this.pollMessage = 'Network Error. Try Again';
                //console.log(error);
            }
        },
    },
}
</script>

<style scoped>
    .rounded {
        border-radius: 7px;
    }
</style>