<template>
    <div class="container" style="margin-top: 35px;">
        <div class="row">
            <div class="col s12 m2 l2"></div>
            <div class="col s12 m8 l8">
                <div class="card" style="border-radius: 7px;">
                    <div class="card-content">
                        <div v-if="validated" class="center">
                            <p class="center-align"><img src="@/assets/rs_verified.svg" style="width: 152px; height: 152px;"/></p>
                        </div>
                        <div v-else class="center">
                            <circular-preloader />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col s12 m2 l2"></div>
        </div>
    </div>
    <global-footer />
</template>

<script>
    import M from 'materialize-css'
    import axios from "axios"
    import GlobalFooter from "@/components/GlobalFooter.vue";
    import CircularPreloader from '@/components/CircularPreloader.vue';

    export default {
        props: ['mOTP'],
        created() {
            document.title = this.$route.meta.title;
        },
        mounted(){
            this.doValidation();
        },
        data(){
            return{
                validated: false
            }
        },
        methods: {
            doValidation(){
                let data = new FormData();
                data.append("alphaVerify", this.mOTP);
                axios.post('https://a.ivoting.co.ke/AhzuJAnDhfIkS/vrfy', data).then((res)=>{
                    let trueCondition = res.data['error'];
                    let successMessage = res.data['message'];
                    if (trueCondition === false) {
                        M.toast({html: ''+successMessage});
                        this.validated = true;
                        this.redirectHome();
                    }else{
                        M.toast({html: ''+successMessage});
                        //console.log(successMessage);
                        // this.redirectHome();
                    }
                }).catch((err) => {
                    M.toast({html: ''+err});
                    //console.log(err);
                })
            },
            redirectHome(){
                this.$router.push({
                    name: 'sin'
                })
            }
        },
        components: { GlobalFooter, CircularPreloader },
    }
</script>

<style>

</style>