import { createRouter, createWebHistory } from 'vue-router'
import routerLogout from '@/functions/routerLogout'
import Home from '../views/HomeView.vue'
import SignUp from '../views/SignUpView'
import ForgotPassword from '../views/ForgotPasswordView'
import NotFound from '../views/NotFoundView.vue'
import SetUp from '../views/SetUpView.vue'
import PasswordRecovery from '../views/PasswordRecoveryView.vue'
import Dashboard from '../views/DashboardView.vue'
import Account from '../views/AccountView.vue'
import Assign from '../views/AssignView.vue'
import Verify from '../views/VerifyView.vue'
import VoterAssign from '../views/VoterAssignView.vue'
import OnBoarding from '../views/OnBoardView.vue'
import iSearch from '../views/iSearchView'
import IndividualAdmin from '../views/IndividualAdminView.vue'
import VStatus from '../views/StatusView.vue'
import adminsV2 from '../views/PaginationOneView.vue'
import assignV2 from '../views/PaginationTwoView.vue'
import PollsDashboard from '../views/PollsDashboardView.vue'
import PollView from '../views/PollView.vue'
import ResultsView from '../views/ResultsView.vue'
import ResultsPoolsView from '../views/ResultsPoolsView.vue'
import CategoryResultsView from '../views/CategoryResultsView.vue'

const routes = [
  {
    path: '/',
    name: 'sin',
    component: Home,
    meta: { title: 'Sign In - iVoting' },
  },
  {
    path: '/sign-up',
    name: 'sup',
    component: SignUp,
    meta: { title: 'Sign Up - iVoting' }
  },
  {
    path: '/forgot-password',
    name: 'fopa',
    component: ForgotPassword,
    meta: { title: 'Forgot Password - iVoting' }
  },
  {
    path: '/s/:mOTP',
    name: 'stp',
    component: SetUp,
    props: true,
    meta: { title: 'Set Up - iVoting' }
  },
  {
    path: '/p/:mPRC',
    name: 'prc',
    component: PasswordRecovery,
    props: true,
    meta: { title: 'Password Recovery - iVoting'}
  },
  {
    path: '/dashboard',
    name: 'router-dash',
    component: Dashboard,
    meta: {title: 'Dashboard - iVoting'},
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") === null) {
        routerLogout();
        //console.log("no auth for dash - router");
      }else if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") !== sessionStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs")){
        routerLogout();
        //console.log("not equal to - router");
      }else{
        //console.log("auth for dash found - router");
        next();
      }
    },
  },
  {
    path: '/account',
    name: 'profile',
    component: Account,
    meta: {title: 'Account - iVoting'},
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") === null) {
        routerLogout();
      }else if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") !== sessionStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs")){
        routerLogout();
      }else{
        next();
      }
    },
  },
  {
    path: '/assign',
    name: 'assn',
    component: Assign,
    meta: {title: 'Assign - iVoting'},
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") === null) {
        routerLogout();
      }else if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") !== sessionStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs")){
        routerLogout();
      }else{
        next();
      }
    },
  },
  {
    path: '/verify',
    name: 'vrify',
    component: Verify,
    meta: {title: 'Verify - iVoting'},
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") === null) {
        routerLogout();
      }else if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") !== sessionStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs")){
        routerLogout();
      }else{
        next();
      }
    },
  },
  {
    path: '/voter-assign/:vAS',
    name: 'vassin',
    component: VoterAssign,
    props: true,
    meta: {title: 'Voter Assign - iVoting'},
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") === null) {
        routerLogout();
      }else if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") !== sessionStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs")){
        routerLogout();
      }else{
        next();
      }
    },
  },
  {
    path: '/on-board',
    name: 'onbrd',
    component: OnBoarding,
    meta: {title: 'Admins - iVoting'},
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") === null) {
        routerLogout();
      }else if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") !== sessionStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs")){
        routerLogout();
      }else{
        next();
      }
    },
  },
  {
    path: '/isearch',
    name: 'stts',
    component: iSearch,
    meta: {title: 'iSearch - iVoting'},
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") === null) {
        routerLogout();
      }else if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") !== sessionStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs")){
        routerLogout();
      }else{
        next();
      }
    },
  },
  {
    path: '/verification-status',
    name: 'vstatus',
    component: VStatus,
    meta: {title: 'Verification Status - iVoting'},
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") === null) {
        routerLogout();
      }else if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") !== sessionStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs")){
        routerLogout();
      }else{
        next();
      }
    },
  },
  {
    path: '/admin-manager/:iad',
    name: 'inad',
    component: IndividualAdmin,
    props: true,
    meta: {title: 'Admin Manager - iVoting'},
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") === null) {
        routerLogout();
      }else if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") !== sessionStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs")){
        routerLogout();
      }else{
        next();
      }
    },
  },
  {
    path: '/admins/:pageValue',
    name: 'pgnt',
    component: adminsV2,
    props: true,
    meta: {title: 'Admins - iVoting'},
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") === null) {
        routerLogout();
      }else if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") !== sessionStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs")){
        routerLogout();
      }else{
        next();
      }
    },
  },
  {
    path: '/assign/:pageValue',
    name: 'assignPhone',
    component: assignV2,
    props: true,
    meta: {title: 'Assign Phone Numbers - iVoting'},
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") === null) {
        routerLogout();
      }else if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") !== sessionStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs")){
        routerLogout();
      }else{
        next();
      }
    },
  },
  {
    path: '/polls/:pageValue',
    name: 'polls',
    component: PollsDashboard,
    props: true,
    meta: {title: 'Polls - iVoting'},
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") === null) {
        routerLogout();
      }else if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") !== sessionStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs")){
        routerLogout();
      }else{
        next();
      }
    },
  },
  {
    path: '/poll/:pageValue',
    name: 'poll',
    component: PollView,
    props: true,
    meta: {title: 'Poll - iVoting'},
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") === null) {
        routerLogout();
      }else if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") !== sessionStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs")){
        routerLogout();
      }else{
        next();
      }
    },
  },
  {
    path: '/results/:pageValue',
    name: 'results',
    component: ResultsView,
    props: true,
    meta: {title: 'Results - iVoting'},
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") === null) {
        routerLogout();
      }else if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") !== sessionStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs")){
        routerLogout();
      }else{
        next();
      }
    },
  },
  {
    path: '/results-p/:pageValue',
    name: 'presults',
    component: ResultsPoolsView,
    props: true,
    meta: {title: 'Results - iVoting'},
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") === null) {
        routerLogout();
      }else if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") !== sessionStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs")){
        routerLogout();
      }else{
        next();
      }
    },
  },
  {
    path: '/category/:pageValue/:categoryID',
    name: 'pool',
    component: CategoryResultsView,
    props: true,
    meta: {title: 'Category Results - iVoting'},
    beforeEnter: (to, from, next) => {
      if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") === null) {
        routerLogout();
      }else if (localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs") !== sessionStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs")){
        routerLogout();
      }else{
        next();
      }
    },
  },
  {
    path: '/:catchAll(.*)',
    name: 'NotFound',
    component: NotFound,
    meta: { title: 'Error 404 - iVoting' }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
