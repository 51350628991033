<template>
    <post-login-nav />
    <side-bar />
    <div class="container">
        <section>
            <div v-if="pagiLoader" class="center">
                <circular-preloader />
            </div>
            <div v-else>
                <div v-if="pagiData.length">
                    <table class="striped hightlight">
                        <thead class="red-text text-darken-4">
                            <tr>
                                <td>Name</td>
                                <td>Username</td>
                                <td>Manage</td>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="single in pagiData" :key="single">
                                <td>{{ single.an }}</td>
                                <td>{{ single.au }}</td>
                                <td>
                                    <router-link :to="{name: 'inad', params: {iad: single.ax}}">
                                        <i class="material-icons red-text text-darken-4">arrow_forward</i>
                                    </router-link>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <section>
                        <ul class="pagination center">
                            <li v-for="index in pagiTotal" :key="index" style="margin-right: 10px; margin-left: 10px;">
                                <div v-if="index === pagiCurrent">
                                    <span class="red-text text-darken-4" style="text-decoration: none; cursor: pointer; text-decoration: line-through;" @click.prevent="loadList(index)">{{ index }}</span>
                                </div>
                                <div v-else>
                                    <span class="" style="text-decoration: none; cursor: pointer;" @click.prevent="loadList(index)">{{ index }}</span>
                                </div>
                            </li>
                        </ul>
                    </section>
            
                </div>

                <div v-else>
                    <p class="center red-text text-darken-4">{{ pagiMessage }}</p>
                </div>
            </div>
        </section>

        <section>
            <div class="fixed-action-btn">
                <a class="btn-floating btn-large red darken-4 modal-trigger" href="#prosthíki">
                    <i class="large material-icons">add</i>
                </a>
            </div>

            <div id="prosthíki" class="modal">
                <div class="modal-content">
                    <h5 class="center red-text text-darken-4">Enrol A New Administrator</h5>
                    <form>

                        <div class="input-field">
                            <input id="adm_fname" type="text" class="validate" required autocomplete="off" v-model="admRegFName">
                            <label for="adm_fname" class="red-text text-darken-4">First Name</label>
                        </div>

                        <div class="input-field">
                            <input id="adm_lname" type="text" class="validate" required autocomplete="off" v-model="admRegLName">
                            <label for="adm_lname" class="red-text text-darken-4">Last Name/Surname</label>
                        </div>

                        <div class="input-field">
                            <input id="adm_tel" type="number" class="validate" required autocomplete="off" v-model="admRegTel">
                            <label for="adm_tel" class="red-text text-darken-4">Phone Number</label>
                        </div>

                        <section>
                            <div v-if="admRegLoader" class="center">
                                <circular-preloader />
                            </div>
                            <div v-else>
                                <button @click.prevent="addAdmin" class="btn red darken-4 white-text btn-rounded full">Add Admin</button>
                            </div>
                        </section>
                    </form>
                </div>
                <div class="modal-footer">
                    <a class="modal-close waves-effect waves-green btn-flat red-text text-darken-4">Close</a>
                </div>
            </div>
        </section>
    </div>
    <global-footer />
</template>

<script>
import PostLoginNav from '@/components/PostLoginNav.vue'
import SideBar from '@/components/SideBar.vue'
import GlobalFooter from '@/components/GlobalFooter.vue'
import CircularPreloader from '@/components/CircularPreloader.vue'
import axios from 'axios'
import M from 'materialize-css'

export default {
    components: { PostLoginNav, SideBar, GlobalFooter, CircularPreloader },
    data(){
        return{
            pagiLoader: true,
            pagiMessage: null,
            pagiData: [],
            pagiTotal: null,
            pagiCurrent: null,
            admRegFName: null,
            admRegLName: null,
            admRegTel: null,
            admRegLoader: false,
            authToken: localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs"),
            godView: 'HOPSxJlnactLG6dWhMYjU',
        }
    },
    props: {
        pageValue: {
            default: 1,
            type: String
        }
    },
    created(){
        document.title = this.$route.meta.title;
    },
    mounted(){
        this.loadList(this.pageValue);
        this.isGodView();
    },
    methods: {
        async loadList(pageValue){
            let data = new FormData();
            data.append("aop", pageValue);
            data.append("aok", this.authToken);

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AhzuJAnDhfIkS/pagiOne', data);
                let returnCondition = response.data.er;
                let returnMessage = response.data.msg;

                if (returnCondition === false) {
                    this.pagiLoader = false;
                    this.pagiData = response.data.th;
                    this.pagiTotal = response.data.pn;
                    this.pagiCurrent = response.data.pc;
                } else {
                    this.pagiLoader = false;
                    this.pagiData = [];
                    this.pagiMessage = returnMessage;
                }
            } catch (error) {
                this.pagiLoader = false;
                this.pagiData = [];
                this.pagiMessage = 'Network Error. Try Again';

            }
        },
        isGodView(){
            let currentUser = window.atob(window.atob(this.authToken));

            if (currentUser != this.godView) {
                this.returnToDash();
            }
        },
        returnToDash(){
            this.$router.push({name: "router-dash"});
        },
        addAdmin(){
            if (this.admRegFName === "" || this.admRegFName === null || this.admRegFName.value === 0) {
                alert("Empty Field - First Name")
            }else if (this.admRegLName === "" || this.admRegLName === null || this.admRegLName.value === 0) {
                alert("Empty Field - Surname")
            }else if (this.admRegTel === "" || this.admRegTel === null || this.admRegTel.value === 0) {
                alert("Empty Field - Phone Number")
            }else {
                this.admRegLoader = true;
                this.TheLordElijah();
            }
        },
        async TheLordElijah(){
            let data = new FormData();
            data.append("new_adm_first_name", this.admRegFName);
            data.append("new_adm_last_name", this.admRegLName);
            data.append("new_adm_phone_no", this.admRegTel);

            try {
                const response = await axios.post("https://a.ivoting.co.ke/AhzuJAnDhfIkS/onAdminReg", data);
                let returnCondition = response.data.error;
                let returnMessage = response.data.message;
                if (returnCondition === false) {
                    this.admRegLoader = false;
                    M.toast({html: ''+returnMessage});
                    // this.loadList(this.pageValue);
                    this.closeModal();
                }else{
                    this.admRegLoader = false;
                    M.toast({html: ''+returnMessage});
                }
            } catch (error) {
                this.admRegLoader = false;
                M.toast({html: 'Network Error. Try Again.'});
            }
        },
        closeModal(){
            var elem = document.getElementById("prosthíki");
            var instance = M.Modal.getInstance(elem);
            instance.close();
        }
    }
}
</script>

<style scoped>
    .full {
        width: 100%;
    }

    .btn-rounded {
        border-radius: 21px;
    }
</style>