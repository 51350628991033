<template>
    <div class="row">
        <div class="col s4 m4 l4">
            <div class="card-panel hoverable center" style="border-radius: 7px;">
                <router-link :to="{name: 'pgnt', params: {pageValue: 1}}" style="text-decoration: none;">
                    <div class="chip">
                        <span class="white-text">Admins</span>
                    </div>
                    <br>
                    <div v-if="adminLoader">
                        <small>-</small>
                    </div>
                    <div v-else>
                        <div v-if="adminCount == null">
                            <small>{{ adminMessage }}</small>
                        </div>
                        <div v-else>
                            <span class="flow-text">{{ adminCount }}</span>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>
        
        <div class="col s4 m4 l4">
            <div class="card-panel hoverable center" style="border-radius: 7px;">
                <router-link :to="{name: 'polls', params: {pageValue: 1}}" style="text-decoration: none;">
                    <div>
                        <div class="chip">
                            <span class="white-text">Polls</span>
                        </div>
                        <br>
                        <div v-if="pollLoader">
                            <small>-</small>
                        </div>
                        <div v-else>
                            <div v-if="pollCount == null">
                                <small>{{ pollMessage }}</small>
                            </div>
                            <div v-else>
                                <span class="flow-text">{{ pollCount }}</span>
                            </div>
                        </div>
                    </div>
                </router-link>
            </div>
        </div>

        <!-- <div class="col s4 m4 l4">
            <div class="card-panel center" style="border-radius: 7px;">
                <div class="chip">
                    <span class="white-text">Links</span>
                </div>
                <br>
                <div v-if="inviteLoader">
                    <small>-</small>
                </div>
                <div v-else>
                    <div v-if="inviteCount == null">
                        <small>{{ inviteMessage }}</small>
                    </div>
                    <div v-else>
                        <span class="flow-text">{{ inviteCount }}</span>
                    </div>
                </div>
            </div>
        </div> -->


        <div class="col s4 m4 l4">
            <div class="card-panel center" style="border-radius: 7px;">
                <div class="chip">
                    <span class="white-text">Users</span>
                </div>
                <br>
                <div v-if="userLoader">
                    <small>-</small>
                </div>
                <div v-else>
                    <div v-if="userCount == null">
                        <small>{{ userMessage }}</small>
                    </div>
                    <div v-else>
                        <span class="flow-text">{{ userCount }}</span>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data(){
            return {
                pollLoader: true,
                pollMessage: null,
                pollCount: null,
                inviteLoader: true,
                inviteMessage: null,
                inviteCount: null,
                userLoader: true,
                userMessage: null,
                userCount: null,
                adminLoader: true,
                adminMessage: null,
                adminCount: null,
                authToken: localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs"),
                pageItems: [
                    {pageID: 1, pageName: 'Admins', pageLink: 'pgnt', pageParam: true, paramValue: {pageValue: 1}, pageIcon: 'supervisor_account', pageDescription: 'Manage Admins'},
                    {pageID: 3, pageName: 'iSearch', pageLink: 'stts', pageParam: false, pageIcon: 'person_search', pageDescription: 'Check The Status of a Voter'},
                ],
            }
        },
        methods: {
            async loadPolls(){
                let data = new FormData();
                data.append('pu', this.authToken);

                try {
                    const response = await axios.post('https://a.ivoting.co.ke/AhzuJAnDhfIkS/lp', data);
                    let returnCondition = response.data.error;
                    let returnMessage = response.data.message;

                    if (returnCondition === false) {
                        this.pollLoader = false;
                        this.pollMessage = null;
                        this.pollCount = response.data.gti;
                    } else {
                        this.pollLoader = false;
                        this.pollMessage = returnMessage;
                        this.pollCount = null
                    }
                } catch (error) {
                    this.pollLoader = false;
                    this.pollMessage = 'NE';
                    this.pollCount = null;
                    // console.log(error);
                }
            },
            // async loadInvites(){
            //     let data = new FormData();
            //     data.append('iu', this.authToken);

            //     try {
            //         const response = await axios.post('https://a.ivoting.co.ke/AhzuJAnDhfIkS/li', data);
            //         let returnCondition = response.data.error;
            //         let returnMessage = response.data.message;

            //         if (returnCondition === false) {
            //             this.inviteLoader = false;
            //             this.inviteMessage = null;
            //             this.inviteCount = response.data.tsi;
            //         } else {
            //             this.inviteLoader = false;
            //             this.inviteMessage = returnMessage;
            //             this.inviteCount = null
            //         }
            //     } catch (error) {
            //         this.inviteLoader = false;
            //         this.inviteMessage = 'NE';
            //         this.inviteCount = null;
            //         // console.log(error);
            //     }
            // },
            async loadUsers(){
                let data = new FormData();
                data.append('uu', this.authToken);

                try {
                    const response = await axios.post('https://a.ivoting.co.ke/AhzuJAnDhfIkS/lu', data);
                    let returnCondition = response.data.error;
                    let returnMessage = response.data.message;

                    if (returnCondition === false) {
                        this.userLoader = false;
                        this.userMessage = null;
                        this.userCount = response.data.fsi;
                    } else {
                        this.userLoader = false;
                        this.userMessage = returnMessage;
                        this.userCount = null
                    }
                } catch (error) {
                    this.userLoader = false;
                    this.userMessage = 'NE';
                    this.userCount = null;
                    // console.log(error);
                }
            },
            async loadAdmins(){
                let data = new FormData();
                data.append('au', this.authToken);

                try {
                    const response = await axios.post('https://a.ivoting.co.ke/AhzuJAnDhfIkS/la', data);
                    let returnCondition = response.data.error;
                    let returnMessage = response.data.message;

                    if (returnCondition === false) {
                        this.adminLoader = false;
                        this.adminMessage = null;
                        this.adminCount = response.data.r;
                    } else {
                        this.adminLoader = false;
                        this.adminMessage = returnMessage;
                        this.adminCount = null
                    }
                } catch (error) {
                    this.adminLoader = false;
                    this.adminMessage = 'NE';
                    this.adminCount = null;
                    // console.log(error);
                }
            },
        },
        beforeMount(){
            this.loadPolls();
            // this.loadInvites();
            this.loadUsers();
            this.loadAdmins();
        },
    }
</script>

<style>

</style>