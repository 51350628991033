<template>
    <post-login-nav />
    <side-bar />
    <div class="container">
        <div v-if="resultLoader" class="center">
            <circular-preloader />
        </div>
        <div v-else>
            <div v-if="resultData.length">
                <table class="highlight striped">
                    <thead class="red-text text-darken-4">
                        <tr>
                            <td>Category</td>
                            <td>Go To</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="category in resultData" :key="category">
                            <td>{{ category.p }}</td>
                            <td><router-link :to="{name: 'pool', params: {pageValue: pageValue, categoryID: category.t}}"><i class="material-icons red-text text-darken-4">arrow_forward</i></router-link></td>
                        </tr>
                    </tbody>
                </table>
                
            </div>
            <div v-else>
                <p class="center red-text text-darken-4">{{ resultMessage }}</p>
            </div>
        </div>
    </div>
    <global-footer />
</template>

<script>
import axios from 'axios';
import PostLoginNav from '@/components/PostLoginNav.vue';
import SideBar from '@/components/SideBar.vue';
import GlobalFooter from '@/components/GlobalFooter.vue';
import CircularPreloader from '@/components/CircularPreloader.vue';
import M from 'materialize-css';

export default {
  components: { PostLoginNav, SideBar, GlobalFooter, CircularPreloader },
    props: {
        pageValue: {
            type: String
        }
    },
    data(){
        return {
            authToken: localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs"),
            resultLoader: true,
            resultMessage: null,
            resultData : [],
        }
    },
    methods: {
        async quickDraw(){
            let data = new FormData()
            data.append('ro_p', this.pageValue);
            data.append('ro_k', this.authToken);

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AhzuJAnDhfIkS/ro', data)
                let returnCondition = response.data.error
                let returnMessage = response.data.message

                if (returnCondition === false) {
                    this.resultLoader = false
                    this.resultMessage = null
                    this.resultData = response.data.thread
                } else {
                    this.resultLoader = false
                    this.resultData = []
                    this.resultMessage = returnMessage
                }
            } catch (error) {
                this.resultLoader = false
                this.resultData = []
                this.resultMessage = 'Network Error. Try Again'
                //console.log(error);
            }
        },
    },
    mounted(){
        this.quickDraw();
    },
    created(){
        document.title = this.$route.meta.title;
    },
}
</script>

<style>

</style>