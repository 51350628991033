<template>
    <post-login-nav />
    <side-bar />
        <div class="container">
            <div v-if="specLoader" class="center">
                <circular-preloader />
            </div>
            <div v-else>
                <div v-if="specData.length">
                    <section>
                        <div class="row">
                            <div class="col s12 m12 l12">
                                <div class="card rounded" v-for="single in specData" :key="single">
                                    <div class="card-content activator">
                                        <div v-if="single.pa === '1'">
                                            <span class="green-text text-darken-4 card-title center">{{ single.pn }}</span>
                                        </div>
                                        <div v-else>
                                            <span class="red-text text-darken-4 card-title center">{{ single.pn }}</span>
                                        </div>
                                        <p class="center"><small>@{{ single.pc }}</small></p>
                                        <p class="center"><small>{{ single.pd }}</small></p>
                                        <br>
                                        <div class="row">
                                            <div class="col s6 m3 l3">
                                                <div class="chip white-text center">
                                                    {{ single.py }}
                                                </div>
                                            </div>
                                            <div class="col s6 m3 l3">
                                                <div class="chip white-text center">
                                                    {{ single.pv }}
                                                </div>
                                            </div>
                                            <div class="col s6 m3 l3">
                                                <div class="chip white-text center">
                                                    {{ single.pp }}
                                                </div>
                                            </div>
                                            <div class="col s6 m3 l3">
                                                <div class="chip white-text center">
                                                    {{ single.pt }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                    <section>
                        <div class="row">
                            <div class="col s6 m6 l6">
                                <poll-link :poll="pageValue"/>
                            </div>

                            <div class="col s6 m6 l6">
                                <poll-voters :poll="pageValue"/>
                            </div>

                            <div class="col s6 m6 l6">
                                <poll-votes :poll="pageValue"/>
                            </div>

                            <div class="col s6 m6 l6">
                                <div class="card rounded">
                                    <div v-if="specCanType === specPool ">
                                        <router-link :to="{name: 'presults', params: {pageValue: pageValue}}">
                                            <div class="card-content">
                                                <span class="card-title red-text text-darken-4 center">Results</span>
                                                <p class="center"><i class="material-icons">north_east</i></p>
                                            </div>
                                        </router-link>
                                    </div>
                                    <div v-else>
                                        <router-link :to="{name: 'results', params: {pageValue: pageValue}}">
                                            <div class="card-content">
                                                <span class="card-title red-text text-darken-4 center">Results</span>
                                                <p class="center"><i class="material-icons">north_east</i></p>
                                            </div>
                                        </router-link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
                <div v-else>
                    <p class="center red-text text-darken-4">{{ specMessage }}</p>
                </div>
            </div>
        </div>
    <global-footer />
</template>

<script>
import axios from 'axios'
import PostLoginNav from '@/components/PostLoginNav.vue'
import SideBar from '@/components/SideBar.vue';
import GlobalFooter from '@/components/GlobalFooter.vue';
import CircularPreloader from '@/components/CircularPreloader.vue';
import PollLink from '@/components/PollLink.vue';
import PollVoters from '@/components/PollVoters.vue';
import PollVotes from '@/components/PollVotes.vue';

export default {
    components: { PostLoginNav, SideBar, GlobalFooter, CircularPreloader, PollLink, PollVoters, PollVotes },
    props: {
        pageValue: {
            type: String
        }
    },
    created(){
        document.title = this.$route.meta.title;
    },
    mounted(){
        this.pollDeets();
    },
    data(){
        return{
            authToken: localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs"),
            specLoader: true,
            specMessage: null,
            specData: [],
            specCanType: null,
            specPool: 'Pools',
        }
    },
    methods: {
        async pollDeets(){
            let data = new FormData();
            data.append('v', this.pageValue);
            data.append('k', this.authToken);

            try {
                const response = await axios.post('https://a.ivoting.co.ke/AhzuJAnDhfIkS/pls-s', data)
                let returnCondition = response.data.error
                let returnMessage = response.data.message

                if (returnCondition === false) {
                    this.specLoader = false
                    this.specMessage = null
                    this.specData = response.data.thread
                    this.specCanType = response.data.thread[0].pp
                } else {
                    this.specLoader = false
                    this.specData = []
                    this.specMessage = returnMessage
                }
            } catch (error) {
                this.specLoader = false
                this.specData = []
                this.specMessage = 'Network Error. Try Again'
                //console.log(error);
            }
        },
        
    }

}
</script>

<style scoped>
    .rounded {
        border-radius: 7px;
    }
</style>