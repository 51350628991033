<template>
  <router-view/>
</template>

<script>
  import M from 'materialize-css';

  export default{
    beforeMount(){
      this.checkForThemes();
    },
    data(){
      return{
        themePreset: localStorage.getItem('theme'),
      }
    },
    methods: {
      toggle(payload){
        this.isDarkMode = payload;
        document.documentElement.className = payload;
        localStorage.setItem('theme', payload);
      },
      checkForThemes(){
        if (this.themePreset === "" || this.themePreset === null || this.themePreset === 0) {
          localStorage.setItem('theme', 'false');
        }
      }
    },
    mounted(){
      M.AutoInit();
      this.toggle(JSON.parse(localStorage.getItem('theme')));
      // document.addEventListener("contextmenu", function (e){
      //   e.preventDefault();
      // }, false);
    },
  }
</script>

<style>
  @import url('https://fonts.googleapis.com/css?family=Montserrat');
  @import '@/styles/dark.css';
  @import '@/styles/light.css';

  html{
    font-family: Montserrat !important;
    scroll-behavior: smooth;
    transition: all 0.5s ease-in-out;
    -webkit-font-smoothing: antialiased;
  }

  body{
    background-color: var(--bg);
    color: var(--text);
  }

  .spec-text{
    color: var(--st)
  }

  input{
    color: var(--text);
  }

  h5{
    color: var(--text);
  }

  p{
    color: var(--text);
  }

  span{
    color: var(--text);
  }

  .card-panel{
    background-color: var(--bg-card) !important;
  }

  .card{
    background-color: var(--bg-card) !important;
  }

  .chip {
    background-color: var(--bg-chip) !important;
  }

  .material-icons{
    color: var(--bg-white) !important;
  }

  .sidenav{
    background-color: var(--bg-sidenav) !important;
  }
  .modal{
    background-color: var(--bg-sidenav) !important;
    border-radius: 7px;
  }
  .modal-footer{
    background-color: var(--bg-sidenav) !important;
  }

  .container-fluid{
    margin-right: 7px;
    margin-left: 7px;
  }

  /* html {
    scroll-behavior: smooth;
    transition: all 0.5s ease-in-out;
    -webkit-font-smoothing: antialiased;
    &.dark {
      body{
          color-scheme: dark;
          background-color: #171717;
          color: white;
        }.card{
          background-color: #262626 !important;
        }p{
          color: white;
        }.card-panel{
          background-color: #262626 !important;
        }.chip{
          background-color: #b71c1c !important;
        }.material-icons{
          color: white;
        }.sidenav{
          background-color: #262626 !important;
        }.modal{
          background-color: #262626 !important;
          border-radius: 7px;
        }.modal-footer{
          background-color: #262626 !important;
        }tbody{
          color: white;
        }span{
          color: white;
        }
    }&.light{
      html{
        color-scheme: light;
      }body{
        background-color: white;
        color: #2c3e50;
      }span{
        color: #2c3e50 !important;
      }.chip{
        background-color: #b71c1c !important;
        color: #b71c1c !important;
      }.material-icons{
        color: white;
      }
    }
  } */
</style>
