<template>
    <header>
        <nav class="nav-wrapper transparent z-depth-0">
            <div class="container">
                <router-link :to="{ name : 'router-dash'}" class="brand-logo left red-text"><img src="@/assets/logo.png" alt="Roman Solutions Logo" style="height: 42px; width: 42px; margin: 10px;"></router-link>
                <a href="#" class="sidenav-trigger right show-on-medium-and-up show-on-small" data-target="mobile-links">
                    <i class="material-icons red-text text-darken-4" style="font-size: 35px;">account_circle</i>
                </a>
            </div>
        </nav>
    </header>
</template>

<script>
    export default {

    }
</script>

<style>

</style>