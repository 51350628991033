<template>
    <pre-login-nav />
    <div class="container">
        <div class="row">
            <div class="col s12 m2 l2"></div>
            <div class="col s12 m8 l8">
                <div class="card" style="border-radius: 7px;">
                    <div class="card-content">
                        <div v-if="validated" class="center">
                            <p class="center-align"><img src="@/assets/rs_password_recovery.svg" style="width: 152px; height: 152px;"/></p>
                            <form>
                                <div class="input-field">
                                    <input id="recovery_pass" type="password" class="validate black-text" required autocomplete="off" v-model="rec_pass">
                                    <label for="recovery_pass" class="red-text text-darken-4">Password</label>
                                </div>

                                <div class="input-field">
                                    <input id="recovery_cpass" type="password" class="validate black-text" required autocomplete="off" v-model="rec_cpass">
                                    <label for="recovery_cpass" class="red-text text-darken-4">Confirm Password</label>
                                </div>

                                <div style="margin-bottom: 21px;">
                                    <label class="left">
                                        <input type="checkbox" @click="togglePassword()"/>
                                        <span id="rec_pass_toggle" class="black-text">Show Password</span>
                                    </label>
                                </div>

                                <br>

                                <div style="margin-top: 14px;">
                                    <div class="center" v-if="progress">
                                        <circular-preloader />
                                    </div>
                                    <div v-else>
                                        <button class="btn red darken-4 white-text" style="width: 100%; border-radius: 7px;" v-on:click.prevent="Zhuli">Recover Password</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div v-else class="center">
                            <circular-preloader />
                        </div>
                    </div>
                </div>
            </div>
            <div class="col s12 m2 l2"></div>
        </div>
    </div>
    <global-footer />
</template>

<script>
    import M from 'materialize-css'
    import axios from "axios"
    import GlobalFooter from '@/components/GlobalFooter.vue'
    import PreLoginNav from '@/components/PreLoginNav.vue'
    import CircularPreloader from '@/components/CircularPreloader.vue'
    
    export default {
        props: ['mPRC'],
        created(){

        },
        mounted(){
            this.validateRequest();
        },
        data(){
            return{
                validated: false,
                progress: false,
                rec_pass: null,
                rec_cpass: null,
            }
        },
        methods: {
            validateRequest(){
                let data = new FormData();
                data.append("recovery_token", this.mPRC);
                axios.post("https://a.ivoting.co.ke/AhzuJAnDhfIkS/prcv", data).then((res) => {
                    let trueCondition = res.data["error"];
                    let successMessage = res.data["message"];
                    if (trueCondition === false) {
                        this.validated = true;
                        // M.toast({ html: "" + successMessage });
                    }else {
                        this.validated = false;
                        M.toast({ html: "" + successMessage });
                        this.redirectHome();
                    }
                }).catch((err) => {
                    this.validated = false;
                    M.toast({ html: "" + err });
                    his.redirectHome();
                });

            },
            togglePassword(){
                var x = document.getElementById("recovery_pass");
                var y = document.getElementById("recovery_cpass");
                
                if (x.type === "password") {
                    var text = document.getElementById("rec_pass_toggle").innerHTML = "Hide Password";
                    x.type = "text";
                    y.type = "text";
                } else {
                    var text = document.getElementById("rec_pass_toggle").innerHTML = "Show Password";
                    x.type = "password";
                    y.type = "password";
                }
            },
            Zhuli(){
                if (this.rec_pass === "" || this.rec_pass === null || this.rec_pass.value === 0) {
                    alert("Empty Field - Password");
                }else if (this.rec_cpass === "" || this.rec_cpass === null || this.rec_cpass.value === 0) {
                    alert("Empty Field - Confirm Password");
                }else {
                    this.progress = true;
                    let data = new FormData();
                    data.append("prc_tkn", this.mPRC);
                    data.append("prc_pass", this.rec_pass);
                    data.append("prc_cpass", this.rec_cpass);
                    axios.post("https://a.ivoting.co.ke/AhzuJAnDhfIkS/prcm", data).then((res) => {
                        let trueCondition = res.data["error"];
                        let successMessage = res.data["message"];
                        if (trueCondition === false) {
                            this.progress = false;
                            M.toast({ html: "" + successMessage });
                            this.resetForm();
                        }else {
                            this.progress = false;
                            M.toast({ html: "" + successMessage });
                            // this.redirectHome();
                        }
                    }).catch((err) => {
                        this.progress = false;
                        M.toast({ html: "" + err });
                        // this.redirectHome();
                    });
                }
            },
            resetForm() {
                this.rec_pass = "";
                this.rec_cpass = "";
                this.redirectHome();
            },
            redirectHome(){
                this.$router.push({
                    name: "sin"
                });
            },
        },
        components: { GlobalFooter, PreLoginNav, CircularPreloader },

    }
</script>

                
<style>

</style>