<template>
    <post-login-nav />
    <side-bar />
    <div class="container">
        <nav class="transparent">
            <div class="nav-wrapper">
                <form>
                    <div class="input-field">
                        <input @keyup="searchMonitor()" id="search" type="search" required v-model="assign_search_param" list="search_output" placeholder="Registration Number" autocomplete="off">
                        <label class="label-icon" for="search"><i class="material-icons">search</i></label>
                        <i class="material-icons">close</i>
                    </div>
                </form>
            </div>
        </nav>

        <div v-if="searchLoading" style="margin-top: 14px; margin-bottom: 14px;" class="center">
            <circular-preloader />
        </div>
        <div v-else>
            <div style="margin-top: 14px;">
                <div v-if="searchUsers.length">
                    <div v-for="voter in searchUsers" :key="voter">
                        <ul class="collection" style="border-radius: 7px; margin-top: 7px; margin-bottom: 7px;">
                            <li class="collection-item hoverable">
                                <span class="card-title green-text text-darken-4">{{voter.isv}}</span><span class="black-text"> - {{voter.isp}} - {{voter.ist}}</span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div v-else>
                    <p class="center red-text text-darken-4">{{searchNegativeResults}}</p>
                </div>
            </div>
        </div>
    </div>
    <global-footer />
</template>

<script>
    import PostLoginNav from '@/components/PostLoginNav.vue'
    import SideBar from '@/components/SideBar.vue'
    import GlobalFooter from '@/components/GlobalFooter.vue'
    import CircularPreloader from '@/components/CircularPreloader.vue';
    import axios from "axios";
    import M from "materialize-css";

    export default {
        components: { PostLoginNav, SideBar, GlobalFooter, CircularPreloader },
        data(){
            return {
                assign_search_param: null,
                searchUsers: [],
                searchNegativeResults: null,
                searchLoading: false,
                authToken: localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs"),
            }
        },
        created(){
            document.title = this.$route.meta.title;
        },
        mounted(){
            
        },
        methods: {
            returnToDash(){
                this.$router.push({name: 'router-dash'})
            },
            searchMonitor(){
                if (this.assign_search_param === "" || this.assign_search_param === null || this.assign_search_param.value === 0) {
                    //alert("Empty Field - Registration Number");
                    this.searchLoading = false;
                    this.searchUsers = [];
                    this.searchNegativeResults = null;
                }else{
                    this.PinkSlipToBlacklistRival();
                }
            },
            async PinkSlipToBlacklistRival(){
                this.searchLoading = true;
                let data = new FormData();
                data.append("tgn", this.assign_search_param);
                data.append("trg", this.authToken);

                try {
                    const response = await axios.post("https://a.ivoting.co.ke/AhzuJAnDhfIkS/stSearch", data)
                    let returnCondition = response.data.error;
                    let returnMessage = response.data.message;

                    if (returnCondition === false) {
                        this.searchLoading = false;
                        this.searchUsers = response.data.thread;
                    } else {
                        this.searchUsers = [];
                        this.searchLoading = false
                        this.searchNegativeResults = returnMessage; 
                    }
                } catch (error) {
                    this.searchUsers = [];
                    this.searchLoading = false;
                    this.searchNegativeResults = 'Network Error. Contact The System Administrator';
                }
            },
        },
    }
</script>

<style>

</style>