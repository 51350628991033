<template>
    <post-login-nav/>
    <side-bar />
    <div class="container">
        <!-- <blockquote>Admin Manager</blockquote> -->
        <div v-if="adminLoader">
            <div v-if="adminData.length">
                <div class="row">
                    <div class="col s12 m2 l2"></div>
                    <div class="col s12 m8 l8">
                        <div class="" style="border-radius: 7px;">
                            <ul v-for="spec in adminData" :key="spec.adminID" style="text-decoration: none;" class="center justify">
                                <section>
                                    <li><i class="material-icons large red-text text-darken-4">account_circle</i></li>
                                    <li class="flow-text">{{spec.name}}</li>
                                    <li class="red-text text-darken-4">@{{ spec.username }}</li>
                                </section>
                                <br>
                                <section>
                                    <div v-if="spec.phone">
                                        <li>+254{{spec.phone}}</li>
                                    </div>
                                    <div v-else>
                                        <li style="cursor: pointer"><span class="red-text text-darken-4 modal-trigger" href="#edit_phone_modal">No Phone Number</span></li>
                                    </div>
                                </section>
                                <br>
                                <section>
                                    <div v-if="spec.active == 1">
                                        <li style="cursor: pointer"><span class="green-text text-darken-4 modal-trigger" href="#nox">Account Active</span></li>
                                    </div>
                                    <div v-else>
                                        <li style="cursor: pointer"><span class="red-text text-darken-4 modal-trigger" href="#lumos">Account Inactive</span></li>
                                    </div>
                                </section>
                            </ul>
                        </div>
                    </div>
                    <div class="col s12 m2 l2"></div>
                </div>
            </div>
            <div v-else>
                <div v-if="adminNegativeMessage">
                    <p class="center red-text text-darken-4">{{adminNegativeMessage}}</p>
                </div>
                <div v-else class="center">
                    <p class="center red-text text-darken-4">Err. Contact The System Administrator.</p>
                </div>
            </div>
        </div>
        <div v-else class="center">
            <circular-preloader />
        </div>
    </div>

    <section>
        <div id="edit_phone_modal" class="modal">
            <div class="modal-content">
                <h6 class="red-text text-darken-4 center">Update Phone Number</h6>
                <form>
                    <div class="input-field">
                        <input id="phone_number" type="number" class="validate" required v-model="adminPhoneUpdateValue" autocomplete="off">
                        <label for="phone_number" class="red-text text-darken-4">Phone Number</label>
                    </div>
                    <section>
                        <div v-if="phoneNumberLoader" class="center">
                            <circular-preloader />
                        </div>
                        <div v-else>
                            <button class="btn red darken-4 waves white-text" style="border-radius: 7px; width: 100%;" @click.prevent="updateAdminPhone">Update</button>
                        </div>
                    </section>
                </form>
            </div>
            <div class="modal-footer">
                <a class="modal-close waves-effect waves-green btn-flat red-text text-darken-4">Close</a>
            </div>
        </div>
    </section>

    <section>
        <div class="modal" id="lumos">
            <div class="modal-content">
                <h6 class="green-text text-darken-4 center">Do You Wish To Proceed?</h6>
                <br>
                <form>
                    <div v-if="accountActivationLoader" class="center">
                        <circular-preloader />
                    </div>
                    <div v-else>
                        <button @click.prevent="activateAccount" class="btn green darken-4 white-text waves" style="border-radius: 7px; width: 100%;">Activate Account</button>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <a class="modal-close waves-effect waves-green btn-flat red-text text-darken-4">Close</a>
            </div>
        </div>
    </section>

    <section>
        <div class="modal" id="nox" style="border-radius: 14px;">
            <div class="modal-content">
                <h6 class="red-text text-darken-4 center">Do You Wish To Proceed?</h6>
                <br>
                <form>
                    <div v-if="accountDeactivationLoader" class="center">
                        <circular-preloader />
                    </div>
                    <div v-else>
                        <button @click.prevent="deactivateAccount" class="btn red darken-4 white-text waves" style="border-radius: 7px; width: 100%;">Deactivate Account</button>
                    </div>
                </form>
            </div>
            <div class="modal-footer">
                <a class="modal-close waves-effect waves-green btn-flat red-text text-darken-4">Close</a>
            </div>
        </div>
    </section>
    <global-footer />
</template>

<script>
import PostLoginNav from '@/components/PostLoginNav.vue';
import SideBar from '@/components/SideBar.vue';
import GlobalFooter from '@/components/GlobalFooter.vue';
import CircularPreloader from '@/components/CircularPreloader.vue';
import axios from "axios";
export default {
    props: ['iad'],
    components: {PostLoginNav, SideBar, GlobalFooter, CircularPreloader,},
    created() {
        document.title = this.$route.meta.title;
    },
    mounted(){
        M.AutoInit();
        this.validateRequest();
    },
    data(){
        return{
            adminLoader: false,
            adminData: [],
            adminNegativeMessage: null,
            phoneNumberLoader: false,
            adminPhoneUpdateValue: null,
            accountActivationLoader: false,
            accountDeactivationLoader: false,
            authToken: window.atob(window.atob(localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs")))
        }
    },
    methods: {
        async validateRequest(){
            let data = new FormData();
            data.append("admin_individual_admin_id", this.iad);
            data.append("admin_individual_alpha_id", this.authToken);

            try {
                const response = await axios.post("https://a.ivoting.co.ke/AhzuJAnDhfIkS/onAdminSpec", data);
                let returnCondition = response.data.error;
                let returnMessage = response.data.message;
                if (returnCondition === false) {
                    this.adminLoader = true;
                    this.adminData = response.data.user_Data;
                }else{
                    this.adminData = [];
                    this.adminLoader = true;
                    this.adminNegativeMessage = returnMessage;
                }

            } catch (error) {
                this.adminData = [];
                this.adminLoader = true;
                this.adminNegativeMessage = 'Network Error. Try Again';
            }
        },
        async DextersLaboratory(){
            this.phoneNumberLoader = true;
            let data = new FormData();
            data.append("update_admin_phone_number", this.adminPhoneUpdateValue);
            data.append("update_admin_beta_id", this.iad);
            data.append("update_admin_phone_by", this.authToken);

            try {
                const response = await axios.post("https://a.ivoting.co.ke/AhzuJAnDhfIkS/onAdminPhoneUpdate", data);
                let returnCondition = response.data.error;
                let returnMessage = response.data.message;
                if (returnCondition === false) {
                    this.phoneNumberLoader = false;
                    M.toast({html: ''+returnMessage});
                    this.closePhoneUpdateModal();
                    this.validateRequest();
                }else{
                    this.phoneNumberLoader = false;
                    M.toast({html: ''+returnMessage});
                }
            } catch (error) {
                this.phoneNumberLoader = false;
                M.toast({html: 'Network Error. Try Again.'});
            }
        },
        async activateAccount(){
            this.accountActivationLoader = true;
            let data = new FormData();
            data.append("activate_beta_id", this.iad);
            data.append("activate_alpha_id", this.authToken);

            try {
                const response = await axios.post("https://a.ivoting.co.ke/AhzuJAnDhfIkS/onAdminActivateAccount", data);
                let returnCondition = response.data.error;
                let returnMessage = response.data.message;
                if (returnCondition === false) {
                    this.accountActivationLoader = false;
                    M.toast({html: ''+returnMessage});
                    this.closeLumosModal();
                    this.validateRequest();
                }else{
                    this.accountActivationLoader = false;
                    M.toast({html: ''+returnMessage});
                }
            } catch (error) {
                this.accountDeactivationLoader = false;
                M.toast({html: 'Network Error. Try Again.'});
            }
        },
        async deactivateAccount(){
            this.accountDeactivationLoader = true;
            let data = new FormData();
            data.append("deactivate_beta_id", this.iad);
            data.append("deactivate_alpha_id", this.authToken);

            try {
                const response = await axios.post("https://a.ivoting.co.ke/AhzuJAnDhfIkS/onAdminDeactivateAccount", data);
                let returnCondition = response.data.error;
                let returnMessage = response.data.message;
                if (returnCondition === false) {
                    this.accountDeactivationLoader = false;
                    M.toast({html: ''+returnMessage});
                    this.closeNoxModal();
                    this.validateRequest();
                }else{
                    this.accountDeactivationLoader = false;
                    M.toast({html: ''+returnMessage});
                }
            } catch (error) {
                this.accountDeactivationLoader = false;
                M.toast({html: 'Network Error. Try Again.'});
            }
        },
        updateAdminPhone(){
            if (this.adminPhoneUpdateValue === "" || this.adminPhoneUpdateValue === null || this.adminPhoneUpdateValue.value === 0) {
                alert("Empty Field - Phone Number");
            }else{
                this.DextersLaboratory();
            }
        },
        closePhoneUpdateModal() {
            var elem = document.getElementById("edit_phone_modal");
            var instance = M.Modal.getInstance(elem);
            instance.close();
        },
        closeNoxModal(){
            var elem = document.getElementById("nox");
            var instance = M.Modal.getInstance(elem);
            instance.close();
        },
        closeLumosModal(){
            var elem = document.getElementById("lumos");
            var instance = M.Modal.getInstance(elem);
            instance.close();
        },
    },
}
</script>

<style>

</style>