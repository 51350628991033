<template>
    <post-login-nav />
    <side-bar />
    <div class="container">
        <p>Verify</p>
    </div>
    <global-footer />
</template>

<script>
import GlobalFooter from "@/components/GlobalFooter.vue";
import PostLoginNav from '@/components/PostLoginNav.vue';
import SideBar from '@/components/SideBar.vue';

export default {
    components: { GlobalFooter, PostLoginNav, SideBar },
    created(){
        document.title = this.$route.meta.title;
    },
    mounted(){
        this.returnToDash();
    },
    methods: {
        returnToDash(){
            this.$router.push({name: 'router-dash'})
        }
    }
}
</script>

<style>

</style>