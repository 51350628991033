<template>
    <post-login-nav />
    <side-bar />
    <div class="container">
        <div class="row">
            <div class="col s0 m2 l2"></div>
            <div class="col s12 m8 l8">
                <div class="center">
                    <ul>
                        <li><i class="large material-icons red-text text-darken-4">account_circle</i></li>
                        <li><span class="flow-text">{{ fromStoreName }}</span></li>
                        <li><span class="red-text text-darken-4">@{{ fromStoreUsername }}</span></li>
                    </ul> 
                </div>
            </div>
            <div class="col s0 m2 l2"></div>
        </div>
    </div>
    <global-footer />
</template>

<script>
import M from 'materialize-css';
import GlobalFooter from '@/components/GlobalFooter.vue'
import PostLoginNav from '@/components/PostLoginNav.vue';
import SideBar from '@/components/SideBar.vue';

export default {
    components: { GlobalFooter, PostLoginNav, SideBar},
    created(){
        document.title = this.$route.meta.title;
    },
    data(){
        return{
            
        }
    },
    mounted(){
        M.AutoInit();
    },
    computed: {
        fromStoreName(){
            return this.$store.state.storeName;
        },
        fromStoreUsername(){
            return this.$store.state.storeUname;
        }
    }
}
</script>

<style>

</style>