import { createStore } from 'vuex'
import M from "materialize-css";
import axios from "axios";
import routerLogout from '@/functions/routerLogout';
// import routerLogout from '@/functions/routerLogout.js';

export default createStore({
  //state = data
  //actions = methods
  //getters = computed
  //mutations = commit and track state changes. 
  //mopdules = 
  //best practice for actions to call mutations then mutations update state
  state: {
    storeIdentifier: null,
    storeName: null,
    storeUname: null,
    storeProductName: 'iVoting',
    storeCompany: 'Roman Solutions',
  },
  getters: {
  },
  mutations: {
    SET_STORE_IDENTIFIER(state, identifier){
      state.storeIdentifier = identifier
    },
    SET_STORE_NAME(state, name){
      state.storeName = name
    },
    SET_STORE_UNAME(state, uname){
      state.storeUname = uname
    }
  },
  actions: {
    decrypt(context){
      let authToken = localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs");
      let decodedString = window.atob(authToken);
      let myJWT = decodedString.split('ILOVECODE');

      context.commit('SET_STORE_IDENTIFIER', myJWT[0])
      context.commit('SET_STORE_NAME', myJWT[1])
      context.commit('SET_STORE_UNAME', myJWT[2])

      // console.log("ID"+myJWT[0]);
      // console.log("Name", myJWT[1]);
      // console.log("Uname", myJWT[2]);
    },
    async getUD(context){
      const authToken = localStorage.getItem("xWtyHBansFSGcOAMsdOAfQmcnKAs");
      let data = new FormData();
      data.append("yHBtxSQmkLIfVByREaxZCV", authToken);
      
      try {
        const response = await axios.post("https://a.ivoting.co.ke/AhzuJAnDhfIkS/yHBtxSQmkLIfVByREaxZCV", data)
        let returnCondition = response.data.or;
        let returnMessage = response.data.sa;

        if (returnCondition == false) {
          // const loggedInId = window.atob(window.atob(response.data.yu));
          // const loggedInName = window.atob(window.atob(response.data.qa));
          // const loggedInUName = window.atob(window.atob(response.data.mx));
          // const loggedInStatus = window.atob(window.atob(response.data.bd));

          const loggedInAll = window.atob(window.atob(response.data.yg));
          const linArr = loggedInAll.split("&&&");
          // console.log(loggedInAll);
          if (linArr[4] === 0) {
            routerLogout();
            M.toast({ html: 'Permissions Revoked By Administrator'});
          }else {
            context.commit('SET_STORE_IDENTIFIER', linArr[0]);
            context.commit('SET_STORE_NAME', linArr[1]);
            context.commit('SET_STORE_UNAME', linArr[2]);

            // context.commit('SET_STORE_IDENTIFIER', loggedInId);
            // context.commit('SET_STORE_NAME', loggedInName);
            // context.commit('SET_STORE_UNAME', loggedInUName);
          }
        }else{
          routerLogout();
          M.toast({ html: 'ST' + returnMessage });
        }
      } catch (error) {
        //console.log(error);
        routerLogout();
        M.toast({ html: 'Network Error. Try Again.' });
      }
    },
  },
  modules: {
  }
})
