<template>
  <pre-login-nav />
  <div class="container">

    <div v-if="initLoader" class="center">
      <circular-preloader />
    </div>
    <div v-else>
      <div v-if="initKey === null">
        <p class="center red-text text-darken-4">{{ initMessage }}</p>
      </div>
      <div v-else>
        <sign-up-card :dataPage="initPage" :dataKey="initKey"/>
      </div>
    </div>  
  </div>
  <global-footer />
</template>

<script>
  import PreLoginNav from '@/components/PreLoginNav.vue'
  import SignUpCard from '@/components/SignUpCard.vue'
  import GlobalFooter from '@/components/GlobalFooter.vue'
  import CircularPreloader from '@/components/CircularPreloader.vue'
  import axios from 'axios'

  export default {
    data(){
      return{//atob to decrypt and btoa to encrypt - base64
        initPage: window.btoa(window.btoa(window.btoa('JHFsbin-mbVkbnd-IOhdoi'))),
        initLoader: true,
        initMessage: null,
        initKey: null,
        initRandom: window.btoa(window.btoa(window.btoa('uBBhicxiuhVNbfgxhiiHfxb')))
      }
    },
    created(){
      document.title = this.$route.meta.title;
    },
    mounted(){
      this.Suki();
    },
    methods: {
      async Suki(){
        let data = new FormData();
        data.append("tzg", this.initPage);
        data.append("txl", this.initRandom);

        try {
          const response = await axios.post('https://a.ivoting.co.ke/AhzuJAnDhfIkS/sfae-re', data);
          let returnCondition = response.data.ro;
          let returnMessage = response.data.gsd;

          if (returnCondition === false) {
            this.initLoader = false;
            this.initKey = response.data.ha;
            this.initMessage = null;
          } else {
            this.initLoader = false;
            this.initMessage = returnMessage;
            this.initKey = null;
          }
        } catch (error) {
          this.initLoader = false;
          this.initMessage = 'Network Error. Try Again.';
          this.initKey = null;
        }
      }
    },
    components: { PreLoginNav, SignUpCard, GlobalFooter, CircularPreloader },
  }
</script>
