<template>
    <post-login-nav />
    <side-bar />
    <div class="container" style="margin-bottom: 14px;">
        <div v-if="validatingRequestProgress">
            <div v-if="specificVoter.length">
                <div v-for="voter in specificVoter" :key="voter.daaID">
                    <p class="center"><i class="large material-icons red-text text-darken-4">account_circle</i></p>
                    <h5 class="center"><span class="red-text text-darken-4">{{ voter.name }}</span></h5>
                    <p class="center"><span class="">{{ voter.regno }}</span></p>
                    <p class="center"><span class="">{{ voter.school }}</span></p>
                    <div v-if="voter.phone">
                        <p class="center"><span>{{voter.phone}}</span></p>
                    </div>
                    <div v-else>
                        <div class="center container">
                            <form>
                                <div class="input-field">
                                    <input id="v_assign_tel" type="number" minlength="10" class="validate" required v-model="vAssignTel">
                                    <label for="v_assign_tel" class="red-text text-darken-4">Phone Number</label>
                                </div>
                                <div v-if="progress" class="center">
                                    <circular-preloader />
                                </div>
                                <div v-else>
                                    <button type="submit" class="btn red darken-4 white-text waves btn-rounded full" @click.prevent="vAssignTelephone">Assign Phone Number</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else>
                <circular-preloader />
            </div>
        </div>
    </div>
    <global-footer />
</template>

<script>
import PostLoginNav from '@/components/PostLoginNav.vue';
import SideBar from '@/components/SideBar.vue';
import GlobalFooter from '@/components/GlobalFooter.vue';
import axios from 'axios';
import M from "materialize-css";
import CircularPreloader from '@/components/CircularPreloader.vue';

export default {
    props: ['vAS'],
    created(){
        document.title = this.$route.meta.title;
    },
    components: {PostLoginNav, SideBar, GlobalFooter, CircularPreloader},
    data(){
        return{
            idValue: this.vAS,
            validatingRequestProgress: false,
            specificVoter: [],
            vAssignTel: null,
            progress: false,
        }
    },
    mounted(){
        this.validateReq();
    },
    methods: {
        validateReq(){
            let data = new FormData();
            data.append("assign_verify_voter", this.vAS);
            axios.post("https://a.ivoting.co.ke/AhzuJAnDhfIkS/asVerify", data).then((res)=>{
                let trueCondition = res.data['error'];
                let returnMessage = res.data['message'];
                if (trueCondition === false) {
                    this.validatingRequestProgress = true;
                    this.specificVoter = res.data.user_Data;
                }else{
                    this.goBackToAssign();
                    M.toast({html: ''+returnMessage});
                }
            }).catch((err) => {
                //axios error
                // M.toast({html: ''+err});
                M.toast({html: 'Error. Contact The System Administrator'});
                this.goBackToAssign();
            });
        },
        goBackToAssign(){
            this.$router.push({name: 'assn'});
        },
        vAssignTelephone(){
            if (this.vAssignTel === "" || this.vAssignTel === null || this.vAssignTel === 0) {
                alert("Empty Field - Voter's Phone Number");
            }else if (this.vAS === "" || this.vAS === null || this.vAS === 0) {
                alert("ErrCode 2019PYT. Contact The System Administrator");
            }else{
                this.progress = true;
                let data = new FormData();
                data.append("voter_assign_id", this.vAS);
                data.append("voter_assign_phone", this.vAssignTel);
                data.append("voter_assign_logger", this.$store.state.storeIdentifier);
                axios.post("https://a.ivoting.co.ke/AhzuJAnDhfIkS/asVerifyTel", data).then((res)=>{
                    let trueCondition = res.data['error'];
                    let returnMessage = res.data['message'];
                    if (trueCondition === false) {
                        this.progress = false;
                        M.toast({html: ''+returnMessage});
                        this.goBackToAssign();
                    }else{
                        this.progress = false;
                        // this.goBackToAssign();
                        M.toast({html: ''+returnMessage});
                    }
                }).catch((err) => {
                    //axios error
                    // M.toast({html: ''+err});
                    M.toast({html: 'Error. Contact The System Administrator'});
                    // this.goBackToAssign();
                });
            }
        }
    }
}
</script>

<style scoped>
    .full {
        width: 100%;
    }

    .btn-rounded {
        border-radius: 21px;
    }
</style>