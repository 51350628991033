<template>
    <div class="row">
        <div class="col s12 m2 l2"></div>
        <div class="col s12 m8 l8">
            <div class="card rounded">
                <div class="card-content">
                    <p class="center-align"><img src="@/assets/rs_sign_up.svg" style="width: 152px; height: 152px;"/></p>
                    <form>
                        <div class="input-field">
                            <input id="sign_up_fname" type="text" class="validate black-text" required autocomplete="off" v-model="sup_fname">
                            <label for="sign_up_fname" class="red-text text-darken-4">First Name</label>
                        </div>

                        <div class="input-field">
                            <input id="sign_up_lname" type="text" class="validate black-text" required autocomplete="off" v-model="sup_lname">
                            <label for="sign_up_lname" class="red-text text-darken-4">Last Name</label>
                        </div>

                        <div class="input-field">
                            <input id="sign_up_uname" type="text" class="validate black-text" required autocomplete="off" v-model="sup_uname">
                            <label for="sign_up_uname" class="red-text text-darken-4">Username</label>
                        </div>

                        <div class="input-field">
                            <input id="sign_up_tel" type="number" class="validate black-text" required autocomplete="off" v-model="sup_tel">
                            <label for="sign_up_tel" class="red-text text-darken-4">Phone Number</label>
                        </div>

                        <div class="input-field">
                            <input id="sign_up_pass" type="password" class="validate black-text" required autocomplete="off" v-model="sup_pass">
                            <label for="sign_up_pass" class="red-text text-darken-4">Password</label>
                        </div>

                        <div class="input-field">
                            <input id="sign_up_cpass" type="password" class="validate black-text" required autocomplete="off" v-model="sup_cpass">
                            <label for="sign_up_cpass" class="red-text text-darken-4">Confirm Password</label>
                        </div>

                        <div style="margin-bottom: 21px;">
                            <label class="center">
                                <input type="checkbox" @click="togglePassword()"/>
                                <span id="user_pass_toggle">Show Password</span>
                            </label>
                        </div>

                        <div>
                            <div class="center" v-if="progress">
                                <circular-preloader />
                            </div>
                            <div v-else>
                                <button class="btn red darken-4 white-text btn-rounded full" v-on:click.prevent="Zhuli">Sign Up</button>
                            </div>
                        </div>

                        <div style="margin-top: 21px;">
                            <p class="center"><router-link :to="{name: 'sin'}"><span>Sign In</span></router-link></p>
                        </div>
                    </form>
                </div>
            </div>
        </div>
        <div class="col s12 m2 l2"></div>
    </div>
</template>

<script>
    import M from 'materialize-css'
    import axios from "axios"
    import CircularPreloader from './CircularPreloader.vue'

    export default {
        props: ['dataPage', 'dataKey'],  //atob to decrypt and btoa to encrypt
        data(){
            return{
                sup_fname: null,
                sup_lname: null,
                sup_uname: null,
                sup_tel: null,
                sup_pass: null,
                sup_cpass: null,
                progress: false,
                dataKeyDec: window.atob(window.atob(window.atob(this.dataKey))),
                dataPageDec: window.atob(window.atob(window.atob(this.dataPage))),
            }
        },
        mounted(){
            //console.log(window.btoa(window.btoa(this.dataKeyDec+'==='+this.dataPageDec)));
        },
        methods: {
            Zhuli(){
                if (this.sup_fname === "" || this.sup_fname === null || this.sup_fname.value === 0) {
                    alert("Empty Field - First Name");
                }
                else if (this.sup_lname === "" || this.sup_lname === null || this.sup_lname.value === 0) {
                    alert("Empty Field - Last Name");
                }
                else if (this.sup_uname === "" || this.sup_uname === null || this.sup_uname.value === 0) {
                    alert("Empty Field - Username");
                }
                else if (this.sup_tel === "" || this.sup_tel === null || this.sup_tel.value === 0) {
                    alert("Empty Field - Phone Number");
                }
                else if (this.sup_pass === "" || this.sup_pass === null || this.sup_pass.value === 0) {
                    alert("Empty Field - Password");
                }
                else if (this.sup_cpass === "" || this.sup_cpass === null || this.sup_cpass.value === 0) {
                    alert("Empty Field - Confirm Password");
                }
                else if (this.dataPage == "" || this.dataPage === null || this.dataPage === 0) {
                    alert("Contact The System Administrator");
                }
                else if (this.dataKey == "" || this.dataKey === null || this.dataKey === 0) {
                    alert("Contact The System Administrator");
                }
                else {
                    this.Meeyuki();
                }
            },
            async Meeyuki(){
                this.progress = true;
                let data = new FormData();
                data.append("rfn", this.sup_fname);
                data.append("rln", this.sup_lname);
                data.append("rue", this.sup_uname);
                data.append("rtl", this.sup_tel);
                data.append("rps", this.sup_pass);
                data.append("rcp", this.sup_cpass);
                let combinedKey = window.btoa(window.btoa(this.dataKeyDec+'&&&'+this.dataPageDec));
                data.append("rcs", combinedKey);
                try {
                    const response = await axios.post("https://a.ivoting.co.ke/AhzuJAnDhfIkS/sup", data)
                    let returnCondition = response.data.ro;
                    let returnMessage = response.data.ge;

                    if (returnCondition == false) {
                        this.progress = false;
                        M.toast({ html: returnMessage });
                        this.resetForm();
                    }else{
                        this.progress = false;
                        M.toast({ html: returnMessage });
                    }
                } catch (error) {
                    this.progress = false;
                    M.toast({ html: 'Network Error. Try Again'});
                }
            },
            resetForm() {
                this.sup_fname = '';
                this.sup_lname = '';
                this.sup_uname = '';
                this.sup_tel = '';
                this.sup_pass = '';
                this.sup_cpass = '';
                this.redirectHome();
            },
            redirectHome() {
                this.$router.push({
                    name: "sin"
                });
            },
            togglePassword(){
                var x = document.getElementById("sign_up_pass");
                var y = document.getElementById("sign_up_cpass");
                
                if (x.type === "password") {
                    var text = document.getElementById("user_pass_toggle").innerHTML = "Hide Password";
                    x.type = "text";
                    y.type = "text";
                } else {
                    var text = document.getElementById("user_pass_toggle").innerHTML = "Show Password";
                    x.type = "password";
                    y.type = "password";
                }
            }
        },
        components: { CircularPreloader, }
    }
</script>

<style scoped>
    .rounded{
        border-radius: 14px;
    }
    .full {
        width: 100%;
    }

    .btn-rounded {
        border-radius: 21px;
    }
</style>